import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FilterConfig } from '../models/filter-config.model';
import { FilterType } from '../models/filter-type.enum';
import { FilterCondition } from '../models/filter-condition.model';
import { FilterOption } from '../models/filter-option.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { TimeframeFilterEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/timeframes-filter.enum';

@Component({
  selector: 'app-grid-filters',
  templateUrl: './grid-filters.component.html',
  styleUrls: ['./grid-filters.component.scss'],
})
export class GridFiltersComponent implements OnChanges {
  @Input() filterConfig: FilterConfig;
  @Input() filterTitle: string;
  @Output() closeSidenav = new EventEmitter<void>();
  @Output() applyFilters = new EventEmitter<FilterConfig>();

  filterType = FilterType;
  selectedColumn: string;
  selectedFilterCondition: FilterCondition;
  conditionUpdates: { [column: string]: string[] } = {};
  isConditionAdded = false;
  fromDate: Date | null = null;
  toDate: Date | null = null;

  @ViewChild('fromInput', {
    read: MatInput,
  })
  fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput,
  })
  toInput: MatInput;

  isCustomDateRangeSelected: boolean | null = false;
  selectedTimeframeFilterCondition: FilterCondition;

  constructor(private snackBar: MatSnackBar) {}

  ngOnChanges() {
    // Reinitialize conditionUpdates whenever filterConfig changes
    this.initializeConditionUpdates();
  }

  initializeConditionUpdates() {
    this.conditionUpdates = {};
    this.filterConfig.conditions.forEach(condition => {
      this.conditionUpdates[condition.column] = [...condition.existingValues];
    });
  }

  addNewCondition() {
    if (this.canAddCondition()) {
      const existingValues = this.conditionUpdates[this.selectedFilterCondition.column];
      const newValueLower = this.selectedFilterCondition.newValue.toLowerCase();

      if (existingValues.map(val => val.toLowerCase()).includes(newValueLower)) {
        this.snackBar.open('This condition is already added.', 'Close', { duration: 3000 });
        return;
      }

      // Add the new condition if it's not a duplicate
      existingValues.push(this.selectedFilterCondition.newValue);
      this.selectedFilterCondition.newValue = '';
      this.isConditionAdded = true;
      this.isCustomDateRangeSelected = false;
      this.selectedTimeframeFilterCondition.newValue = '';

      //clear date filter
      if (this.fromInput) this.fromInput.value = '';

      if (this.toInput) this.toInput.value = '';
    }
  }

  onApplyFilters() {
    // Update filterConfig with accumulated changes
    Object.keys(this.conditionUpdates).forEach(column => {
      const conditionIndex = this.filterConfig.conditions.findIndex(c => c.column === column);
      if (conditionIndex > -1) {
        this.filterConfig.conditions[conditionIndex].existingValues = this.conditionUpdates[column];
      }
    });

    // We want to reset this variable for the next time the filter sidenav is opened
    this.isConditionAdded = false;

    this.applyFilters.emit(this.filterConfig);
    this.closeSidenav.emit();
  }

  onClose() {
    this.closeSidenav.emit();
  }

  onColumnChange(column: string) {
    this.selectedColumn = column;
    this.selectedFilterCondition = this.filterConfig.conditions.find(c => c.column === column);
    this.selectedTimeframeFilterCondition = { ...this.selectedFilterCondition };

    //clear date filter
    if (this.fromInput) this.fromInput.value = '';

    if (this.toInput) this.toInput.value = '';
  }

  getDescription(options: FilterOption[], id: string): string {
    const option = options.find(o => o.id === id);
    return option ? option.description : '';
  }

  getUserSelectionDescription(userSelected: any): string {
    return userSelected ? userSelected.userName : '';
  }

  getDescriptionDateWithOptions(options: FilterOption[], id: string): string {
    const option = options.find(o => o.id === id);
    return option ? option.description.trim() : id;
  }

  canAddCondition(): boolean {
    if (this.selectedFilterCondition && this.selectedFilterCondition.type === FilterType.Date) {
      // Ensure both dates are selected and "from" date is not after "to" date
      return this.fromDate && this.toDate && this.fromDate <= this.toDate;
    }

    if (
      this.selectedFilterCondition &&
      this.selectedFilterCondition.type === FilterType.DateWithOptions
    ) {
      // Ensure both dates are selected and "from" date is not after "to" date when timeframe is custom or select an option from dropdown
      return (
        (TimeframeFilterEnum[this.selectedTimeframeFilterCondition.newValue] ===
          TimeframeFilterEnum[TimeframeFilterEnum.CustomDateRange] &&
          this.fromDate &&
          this.toDate &&
          this.fromDate <= this.toDate) ||
        (this.selectedFilterCondition &&
          this.selectedFilterCondition.newValue &&
          this.selectedFilterCondition.newValue.trim().length > 0 &&
          TimeframeFilterEnum[this.selectedTimeframeFilterCondition.newValue] !==
            TimeframeFilterEnum[TimeframeFilterEnum.CustomDateRange] &&
          this.conditionUpdates[this.selectedFilterCondition.column].length < 1)
      );
    }

    return (
      this.selectedFilterCondition &&
      this.selectedFilterCondition.newValue &&
      this.selectedFilterCondition.newValue.trim().length > 0
    );
  }

  selectedTimeframeChanged() {
    const selectedTimeframe = TimeframeFilterEnum[this.selectedTimeframeFilterCondition.newValue];
    if (selectedTimeframe === TimeframeFilterEnum[TimeframeFilterEnum.CustomDateRange]) {
      this.isCustomDateRangeSelected = true;
    } else {
      this.isCustomDateRangeSelected = false;
      this.selectedFilterCondition.newValue = this.selectedTimeframeFilterCondition.newValue;
    }
  }

  handleOnSelectedUser(user: any) {
    this.conditionUpdates[this.selectedFilterCondition.column] = [];

    this.selectedFilterCondition.newValue = user.userName;
    this.selectedFilterCondition.extraValues = {
      userName: user.userName,
      id: user.id,
      roleName: user.roleNames,
    };
  }

  updateBooleanValue(value: boolean): void {
    // We do this parsing because in 'newValue' we store everything as string (free text, GUIDs, numbers and booleans)
    this.selectedFilterCondition.newValue = value ? 'True' : 'False';
  }

  onDateChange(event: any, type: 'from' | 'to'): void {
    const date = event ? new Date(event) : null;
    if (type === 'from') {
      this.fromDate = date;
    } else {
      this.toDate = date;
    }
    this.updateDateRangeValue();
  }

  updateDateRangeValue(): void {
    if (this.fromDate && this.toDate && this.fromDate > this.toDate) {
      this.snackBar.open('The "From" date cannot be after the "To" date.', 'Close', {
        duration: 3000,
      });
      return;
    }

    const fromDateStr = this.fromDate ? formatDate(this.fromDate, 'MM/dd/yyyy', 'en-US') : '';
    const toDateStr = this.toDate ? formatDate(this.toDate, 'MM/dd/yyyy', 'en-US') : '';
    this.selectedFilterCondition.newValue =
      fromDateStr && toDateStr ? `${fromDateStr} - ${toDateStr}` : '';
  }

  handleOnRemoveCriteria(index: number): void {
    this.conditionUpdates[this.filterConfig.conditions[index].column] = [];

    this.isConditionAdded = false;

    Object.keys(this.conditionUpdates).forEach(column => {
      if (this.conditionUpdates[column].length > 0) {
        this.isConditionAdded = true;
        return;
      }
    });
  }
}
