import { FormControl, FormGroup, Validators } from '@angular/forms';

export class FlyguysGridNoteForm extends FormGroup {
  constructor() {
    super({
      subject: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
      categoryId: new FormControl<string>('', Validators.required),
      departmentId: new FormControl<string>('', Validators.required),
      allowCustomer: new FormControl<boolean>(false, { nonNullable: true }),
      allowPilot: new FormControl<boolean>(false, { nonNullable: true }),
      body: new FormControl<string>('', Validators.required),
    });
  }

  /**
   * Builds a JSON-like representation for API consumption
   * @returns any
   */
  asJSON() {
    return {
      ...this.value,
      allowCustomer: String(this.get('allowCustomer').value),
      allowPilot: String(this.get('allowPilot').value),
    };
  }
}
