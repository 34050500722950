import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-file-management-info-block',
  templateUrl: './file-management-info-block.component.html',
  styleUrls: ['./file-management-info-block.component.scss'],
})
export class FileManagementInfoBlockComponent {
  @Input() title: string;
  expanded = true;
}
