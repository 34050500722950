import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Observable,
  map,
  catchError,
  of,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  finalize,
} from 'rxjs';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PagedResultDto } from '@abp/ng.core';

import {
  CustomersDto,
  enumState,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import {
  GetIdentityUsersInput,
  IdentityUserDto,
  IdentityUserService,
} from '@volo/abp.ng.identity/proxy';

export type UserData = { id: String; userName: String; roleNames: Array<string> };

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
})
export class UserAutocompleteComponent implements OnInit {
  @Input() preloadedUser?: IdentityUserDto;
  @Input() required?: boolean = false;
  @Output() userSelected: EventEmitter<UserData | null>;
  filteredOptions: Observable<IdentityUserDto[]>;
  search: FormGroup;
  loading: boolean = false;

  constructor(private identityService: IdentityUserService) {
    this.userSelected = new EventEmitter();
  }

  ngOnInit(): void {
    this.search = new FormGroup({
      term: new FormControl<string>(this.preloadedUser?.name || ''),
    });

    if (this.required) {
      this.search.get('term').addValidators(Validators.required);
    }

    this.filteredOptions = this.search?.get('term').valueChanges.pipe(
      debounceTime(333),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term?.length) {
          return this.searchCustomer(term.toLowerCase());
        } else if (term?.length === 0) {
          this.emitUser(null);
        }

        return of([]);
      }),
    );
  }

  /**
   * Handles customer selection on the autocomplete
   * @param event MatAutocompleteSelectedEvent
   */
  handleUserSelection(event: MatAutocompleteSelectedEvent) {
    const user: IdentityUserDto = event.option.value;

    this.emitUser({ id: user.id, userName: user.name, roleNames: user.roleNames });
  }

  /**
   * Formats the name of the customer to display
   * @param user any
   * @returns string
   */
  formatUser(user: any): string {
    return user?.name || user;
  }

  /**
   * Clears the search query
   */
  clear() {
    this.search.get('term').setValue('', { emitEvent: false });
  }

  /**
   * Emits the passed value to consumers
   * @param data CustomerData | null
   */
  private emitUser(data: UserData | null) {
    this.userSelected.emit(data);
  }

  /**
   * Searches for customers by filtering using the name
   * @param name string
   * @returns Observable<CustomersDto[]>
   */
  private searchCustomer(name: string): Observable<CustomersDto[]> {
    const input2: GetIdentityUsersInput = {
      filter: name,
      skipCount: 0,
      maxResultCount: 100,
      isExternal: false,
    };

    this.loading = true;

    return this.identityService.getList(input2).pipe(
      map((data: PagedResultDto<IdentityUserDto>) => {
        let users: IdentityUserDto[] = [];

        if (data?.totalCount > 0) {
          this.search.get('term').setErrors(null);

          users = data.items.map(x => {
            return { ...x, name: x.userName };
          });
        } else if (data?.totalCount === 0) {
          this.search.get('term').setErrors({ notFound: true });
        }

        return users;
      }),
      catchError(e => {
        console.error('Error while fetching users', e);
        return of([]);
      }),
      finalize(() => (this.loading = false)),
    );
  }
}
