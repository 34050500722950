<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="container p-0" *ngIf="transferFilesStatusResponse && transferFilesPreStartResponse">
    <div class="row mb-3">
      <div class="col-11 text-start">
        <strong>File Transfer</strong>
      </div>
      <div class="col-1 text-end">
        <span (click)="onClickClose()" class="close-button">x</span>
      </div>
      <div class="col-12"><hr class="mt-2 mb-2" /></div>
    </div>
    <div
      class="row justify-content-center mb-3"
      *ngIf="
        transferFilesStatusResponse.isFinished &&
        (!transferFilesStatusResponse.finishDate ||
          transferFilesStatusResponse.totalFiles !=
            transferFilesStatusResponse.filesAlreadyTransferred)
      "
    >
      <div class="col-10">
        <strong class="text-danger"
          >Process started at
          {{ transferFilesStatusResponse.startDate + 'Z' | date: 'MM/dd/yyyy HH:mm' }} hs. Finished
          with errors.</strong
        >
      </div>
      <div class="col-2 text-end"></div>
    </div>
    <div
      class="row justify-content-center mb-3"
      *ngIf="
        transferFilesStatusResponse.isFinished &&
        transferFilesStatusResponse.finishDate &&
        transferFilesStatusResponse.totalFiles ==
          transferFilesStatusResponse.filesAlreadyTransferred
      "
    >
      <div class="col-8">
        <strong>Last transfer: </strong>
        <span *ngIf="transferFilesStatusResponse.totalFiles == 1"
          >{{ transferFilesStatusResponse.totalFiles }} File ({{
            formatBytes(transferFilesStatusResponse.totalData)
          }}) on
          {{ transferFilesStatusResponse.finishDate + 'Z' | date: 'MM/dd/yyyy HH:mm' }} hs.</span
        >
        <span *ngIf="transferFilesStatusResponse.totalFiles > 1"
          >{{ fileList?.totalCount }} Files ({{
            formatBytes(transferFilesStatusResponse.totalData)
          }}) on
          {{ transferFilesStatusResponse.finishDate + 'Z' | date: 'MM/dd/yyyy HH:mm' }} hs.</span
        >
      </div>
      <div class="col-4 text-end">
        <a
          href="javascript:void(0)"
          class="primary-link"
          *ngIf="!showFileList"
          (click)="handleOnViewTransferredFiles(true)"
          >Show Files</a
        >
        <a
          href="javascript:void(0)"
          class="primary-link"
          *ngIf="showFileList"
          (click)="handleOnViewTransferredFiles(false)"
          >Hide Files</a
        >
      </div>
      <div class="col-12 mt-3" *ngIf="wasInProgress && finishedHere && !enqueuedNow">
        <div class="alert alert-success d-flex" role="alert">
          <span class="material-symbols-outlined me-2"> check_circle </span>
          <span *ngIf="transferFilesStatusResponse.totalFiles == 1" class="mt-1"
            >{{ transferFilesStatusResponse.totalFiles }} file ({{
              formatBytes(transferFilesStatusResponse.totalData)
            }}) successfully transferred</span
          >
          <span *ngIf="transferFilesStatusResponse.totalFiles > 1" class="mt-1"
            >{{ transferFilesStatusResponse.totalFiles }} files ({{
              formatBytes(transferFilesStatusResponse.totalData)
            }}) successfully transferred</span
          >
        </div>
      </div>
    </div>
    <div class="row justify-content-center mb-3" *ngIf="transferFilesStatusResponse.inProgress">
      <div class="col-8">
        <span *ngIf="transferFilesStatusResponse.startDate"
          >The transfer has not been finished yet. It started at
          {{ transferFilesStatusResponse.startDate + 'Z' | date: 'MM/dd/yyyy HH:mm' }} hs.</span
        >
        <span *ngIf="!transferFilesStatusResponse.startDate"
          >The transfer has not been finished yet. It is enqueue waiting to be processed.</span
        >
      </div>
      <div class="col-4 text-end">
        <a
          href="javascript:void(0)"
          class="primary-link"
          *ngIf="!showFileList"
          (click)="handleOnViewTransferredFiles(true)"
          >Show Files</a
        >
        <a
          href="javascript:void(0)"
          class="primary-link"
          *ngIf="showFileList"
          (click)="handleOnViewTransferredFiles(false)"
          >Hide Files</a
        >
      </div>
    </div>
    <div class="row justify-content-center mb-3" *ngIf="showFileList">
      <div class="col-12">
        <span>Current files on S3 bucket</span>
        <div class="grid" *ngIf="fileList">
          <ngx-datatable
            [rows]="fileList.items"
            [headerHeight]="60"
            [footerHeight]="55"
            [rowHeight]="50"
            [offset]="pageNumber"
            [count]="fileList.totalCount"
            [list]="list"
            [limit]="clientMaxResultCount"
            [externalPaging]="true"
            (page)="setPage($event)"
            [trackByProp]="'fileName'"
            [tableName]="'files'"
            default
            #dataTable
          >
            <ngx-datatable-column
              name="{{ 'missionsService::FileName' | abpLocalization }}"
              prop="fileName"
              [minWidth]="130"
              [width]="500"
              [sortable]="false"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.fileName || '--' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{ 'missionsService::Size' | abpLocalization }}"
              prop="fileSize"
              [width]="200"
              [sortable]="false"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ formatBytes(row.fileSize) }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-curPage="curPage"
              >
                <app-table-paginator
                  [(maxResultCount)]="list.maxResultCount"
                  [(clientMaxResultCount)]="clientMaxResultCount"
                  [rowCount]="rowCount"
                  [pageSize]="pageSize"
                  [(pageNumber)]="pageNumber"
                  [curPage]="curPage"
                  [dataTable]="dataTable"
                  (clientMaxResultCountChange)="onPaginationChange($event)"
                >
                </app-table-paginator>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
    <div
      class="row mb-3"
      *ngIf="!transferFilesStatusResponse.inProgress && transferFilesStatusResponse.inQueue"
    >
      <div class="col-12">
        <span *ngIf="transferFilesPreStartResponse.totalFiles == 1"
          >The process is already in queue. {{ transferFilesStatusResponse.totalFiles }} File ({{
            formatBytes(transferFilesStatusResponse.totalData)
          }}).</span
        >
        <span *ngIf="transferFilesPreStartResponse.totalFiles > 1"
          >The process is already in queue. {{ transferFilesStatusResponse.totalFiles }} Files ({{
            formatBytes(transferFilesStatusResponse.totalData)
          }}).</span
        >
      </div>
    </div>
    <div
      class="row justify-content-center mb-3"
      *ngIf="
        !transferFilesStatusResponse.inProgress && transferFilesPreStartResponse.totalFiles > 0
      "
    >
      <div class="col-12" *ngIf="showWarning">
        <div class="row">
          <div class="col-12 d-flex mb-3">
            <span class="material-symbols-outlined me-2 text-danger"> warning </span>
            <span class="mt-1" *ngIf="preTransferResult.totalFiles == 1"
              >Please confirm whether you want to transfer {{ preTransferResult.totalFiles }} file
              ({{ formatBytes(preTransferResult.totalData) }})
            </span>
            <span class="mt-1" *ngIf="preTransferResult.totalFiles > 1"
              >Please confirm whether you want to transfer these
              {{ preTransferResult.totalFiles }} files ({{
                formatBytes(preTransferResult.totalData)
              }})
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-end">
            <button
              class="btn btn-secondary btn-sm me-2"
              type="button"
              (click)="handleOnCancelWarning()"
            >
              Cancel
            </button>

            <button
              mat-flat-button
              color="primary"
              class="common-button"
              type="button"
              (click)="handleOnAcceptWarning()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="enqueuedNow">
        <div class="alert alert-primary d-flex" role="alert">
          <span class="material-symbols-outlined me-2"> info </span>
          <span *ngIf="preTransferResult.totalFiles == 1" class="mt-1"
            >{{ preTransferResult.totalFiles }} file ({{
              formatBytes(preTransferResult.totalData)
            }}) queued for transfer</span
          >
          <span *ngIf="preTransferResult.totalFiles > 1" class="mt-1"
            >{{ preTransferResult.totalFiles }} files ({{
              formatBytes(preTransferResult.totalData)
            }}) queued for transfer</span
          >
        </div>
      </div>

      <div class="col-8 d-flex align-items-center" *ngIf="!showWarning">
        <strong *ngIf="transferFilesPreStartResponse.totalFiles == 1"
          >{{ transferFilesPreStartResponse.totalFiles }} File to transfer ({{
            formatBytes(transferFilesPreStartResponse.totalData)
          }})
        </strong>
        <strong *ngIf="transferFilesPreStartResponse.totalFiles > 1"
          >{{ transferFilesPreStartResponse.totalFiles }} Files to transfer ({{
            formatBytes(transferFilesPreStartResponse.totalData)
          }})</strong
        >
      </div>
      <div class="col-4 text-end" *ngIf="!showWarning">
        <button
          mat-flat-button
          color="primary"
          class="common-button"
          type="button"
          (click)="handleOnTransferFiles()"
          [disabled]="transferFilesStatusResponse.inProgress"
          [class.disabled-button]="transferFilesStatusResponse.inProgress"
        >
          <mat-icon>move_up</mat-icon>
          <span
            *ngIf="
              !transferFilesStatusResponse.inProgress &&
              !transferFilesStatusResponse.inQueue &&
              !enqueuedNow
            "
            >Initiate Transfer</span
          >
          <span
            *ngIf="
              (!transferFilesStatusResponse.inProgress && transferFilesStatusResponse.inQueue) ||
              enqueuedNow
            "
            >Re-Queue Transfer</span
          >
        </button>
      </div>
    </div>
    <div
      class="row justify-content-center mb-3"
      *ngIf="!transferFilesStatusResponse.inProgress && !transferFilesPreStartResponse.totalFiles"
    >
      <div class="col-12 d-flex align-items-center">
        <strong>No files to transfer</strong>
      </div>
    </div>
    <div class="row justify-content-center mb-3" *ngIf="transferFilesStatusResponse.inProgress">
      <div class="col-8">
        <strong>Transfer initiated</strong>
      </div>
      <div class="col-4"></div>
      <div class="col-12 mt-2">
        Transfer will continue to run even if you are not connected to the internet or log out.
      </div>
    </div>
    <div class="row justify-content-center mb-2" *ngIf="transferFilesStatusResponse.inProgress">
      <div class="col-1">
        <span class="material-symbols-outlined progress-icon"> arrow_upload_progress </span>
      </div>
      <div class="col-11 p-1">
        <span
          >{{ transferFilesStatusResponse.filesAlreadyTransferred }} of
          {{ transferFilesStatusResponse.totalFiles }} Files Transferred </span
        ><br />
      </div>
    </div>
    <div class="row justify-content-end" *ngIf="transferFilesStatusResponse.inProgress">
      <div class="col-1">
        <span class="material-symbols-outlined mt-1 progress-icon"> data_check </span>
      </div>
      <div class="col-9 p-1">
        <span
          >{{ formatBytes(transferFilesStatusResponse.totalDataAlreadyTransferred) }} of
          {{ formatBytes(transferFilesStatusResponse.totalData) }}</span
        ><br />
        <progress max="100" [value]="progressValue"></progress>
      </div>
      <div class="col-2 d-flex align-items-center">
        <span>{{ progressValue }} %</span>
      </div>
    </div>
  </div>
  <div class="container p-0" *ngIf="!transferFilesStatusResponse && !transferFilesPreStartResponse">
    <div class="spinner"></div>
  </div>
</mat-dialog-content>
