import { RestService, Rest, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ShipmentCreationDto } from '../models/shipment-creation.dto';
import { ShipmentDto } from '../models/shipment.dto';
import { PaginationSortingAndGlobalSearch } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { ShipmentFilters } from '../models/shipment-filters';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  apiName = 'missionsService';

  create = (input: ShipmentCreationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ShipmentDto>(
      {
        method: 'POST',
        url: '/api/missions-service/shipments',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (
    filters: ShipmentFilters,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    missionId: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<ShipmentDto>>(
      {
        method: 'POST',
        url: '/api/missions-service/shipments/list',
        body: {
          ...filters,
          paginationSortingAndSearch,
          missionId,
        },
      },
      { apiName: this.apiName, ...config },
    );

  searchByTrackingNumber = (trackingNumber: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ShipmentDto>(
      {
        method: 'GET',
        url: `/api/missions-service/shipments/by-tracking-number/${trackingNumber}`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (shipmentId: string, input: ShipmentCreationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ShipmentDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/shipments/${shipmentId}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (missionId: string, shipmentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/shipments/delete-shipment-mission/${missionId}/${shipmentId}`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
