import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

import { PagedResultDto } from '@abp/ng.core';
import { AddShipmentModalComponent } from './add-shipment-modal.component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ShipmentDto } from './models/shipment.dto';
import { ShipmentService } from './services/shipment.service';
import { ShipmentFilters } from './models/shipment-filters';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { AddShipmentDto } from './models/add-shipment.dto';

@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss'],
})
export class ShipmentsComponent implements OnInit {
  @Input() missionId: string;
  @Input() missionShipmentDetails: AddShipmentDto = null;
  @ViewChild('dataTable') table: DatatableComponent;

  data: PagedResultDto<ShipmentDto>;
  form: FormGroup;
  isModalOpen = false;
  selected: ShipmentDto;

  pageNumber = 0;
  clientMaxResultCount = 10;
  list = {
    maxResultCount: 10,
  };

  constructor(
    private readonly shipmentService: ShipmentService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private confirmation: ConfirmationService,
  ) {
    this.form = this.fb.group({
      searchInput: [''],
    });
  }

  ngOnInit(): void {
    this.loadShipments();
  }

  loadShipments() {
    this.spinner.show();

    const filterShipment: ShipmentFilters = {
      trackingNumbers: [],
      carrierIds: [],
      creationDates: [],
      estimatedArrivalDates: [],
      creatorIds: [],
    };

    this.shipmentService
      .getList(
        filterShipment,
        {
          skipCount: this.pageNumber * this.list.maxResultCount,
          maxResultCount: this.list.maxResultCount,
          sorting: '',
          filter: this.form.get('searchInput')?.value || '',
        },
        this.missionId,
      )
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(response => {
        this.data = response;
      });
  }

  onGlobalSearch(): void {
    this.pageNumber = 0;
    this.loadShipments();
  }

  setPage(pageInfo): void {
    this.pageNumber = pageInfo.offset;
    this.loadShipments();
  }

  onPaginationChange(event: any): void {
    this.list.maxResultCount = event;
    this.pageNumber = 0;
    this.loadShipments();
  }

  view(shipment: ShipmentDto): void {
    const modalData: AddShipmentDto = {
      ...this.missionShipmentDetails,
      shipmentDetails: shipment,
      isReadOnly: true,
    };

    this.dialog.open(AddShipmentModalComponent, {
      width: '900px',
      data: modalData,
    });
  }

  edit(shipment: ShipmentDto): void {
    const modalData: AddShipmentDto = {
      ...this.missionShipmentDetails,
      shipmentDetails: shipment,
      isReadOnly: false,
      isEdit: true,
    };

    const dialogRef = this.dialog.open(AddShipmentModalComponent, {
      width: '900px',
      data: modalData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        if (result.isEdit) {
          this.shipmentService.update(result.shipmentId, result).subscribe({
            next: () => {
              this.loadShipments();
              this.spinner.hide();
            },
            error: error => {
              console.error('Error updating shipment:', error);
              this.spinner.hide();
            },
          });
        }
      }
    });
  }

  delete(row: ShipmentDto): void {
    const warnMessage = 'Are you sure you want to delete this shipment?';

    this.confirmation
      .warn(warnMessage, 'missionsService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .subscribe((status: any) => {
        if (status === 'confirm') {
          this.spinner.show();
          this.shipmentService
            .delete(this.missionId, row.id)
            .pipe(finalize(() => this.spinner.hide()))
            .subscribe({
              next: () => {
                this.loadShipments();
              },
              error: error => {
                console.error('Error deleting shipment:', error);
              },
            });
        }
      });
  }

  openAddShipmentModal(): void {
    const modalData: AddShipmentDto = {
      ...this.missionShipmentDetails,
      isReadOnly: false,
      isEdit: false,
    };

    const dialogRef = this.dialog.open(AddShipmentModalComponent, {
      width: '900px',
      data: modalData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        this.shipmentService.create(result).subscribe({
          next: () => {
            this.loadShipments();
            this.spinner.hide();
          },
          error: error => {
            console.error('Error creating shipment:', error);
            this.spinner.hide();
          },
        });
      }
    });
  }

  showNoteModal(note: string): void {
    if (!note) return;

    this.confirmation.info(note, 'Shipment Note', {
      hideCancelBtn: true,
      yesText: 'Close',
    });
  }
}
