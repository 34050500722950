import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridFiltersComponent } from './grid-filters/grid-filters.component';
import { FilterChipComponent } from './filter-chip/filter-chip.component';
import { MaterialSharedModule } from '../../material-shared/material-shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAutocompleteComponent } from './users/user-autocomplete.component';

@NgModule({
  declarations: [GridFiltersComponent, FilterChipComponent, UserAutocompleteComponent],
  imports: [CommonModule, MaterialSharedModule, FormsModule, ReactiveFormsModule],
  exports: [GridFiltersComponent, FilterChipComponent],
})
export class GridFiltersModule {}
