import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PilotSourcingDto, PilotSourcingTableDto } from '../../models/pilot-sourcing-dto';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { enumState } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared';
import {
  AvailabilityService,
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  PilotExperienceLevelesService,
  PilotStatesService,
  StatusService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  AvailabilityDto,
  GetAvailabilitiesInput,
  GetIndustryInput,
  GetPilotExperienceLevelInput,
  GetPilotStateInput,
  PilotExperienceLevelesDto,
  PrioritiesDto,
  StatusDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  ABP,
  ConfigStateService,
  HttpWaitService,
  ListService,
  LocalizationService,
  PagedResultDto,
  PermissionService,
} from '@abp/ng.core';
import { GetPilotsForSourcingInput } from '../../models/pilot-for-sorucing-request';
import { Observable, Subscription, tap } from 'rxjs';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { LoadingOverlayService } from '../../../services/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import {
  BadgesDto,
  EquipmentsDto,
  GetBadgeInput,
  GetLocationInput,
  LocationsDto,
  MissionUpdateLocation,
  MissionUpdateStorage,
  MissionsDto,
  StatusMissionOrderDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  BadgesService,
  EquipmentsService,
  LocationsService,
  MissionsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  GetPilotSourcingExtraFilters,
  GetPilotSourcingFilters,
} from '../../models/get-pilot-sourcing-filters-dto';
import { PaginationSortingAndGlobalSearchDto } from '../../models/pagination-sorting-and-global-search-dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { MatExpansionPanel } from '@angular/material/expansion';
import { FlyGuysDashboardMarkers, FlyguysMapComponent, pilotData, missionData } from '@flyguys/map';
import { MissionsGridFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/missions-grid-filters';
import { StatusAndAssignedPermissions } from '../../../shared/grid-filters/models/status-and-assigned-permissions';
import { FilterConfig } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { MissionsPilotWorkbenchFilter } from './models/missions-pilot-workbench-filter';
import { PrioritiesService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import {
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import { StatusMissionOrderService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/status-mission-order.service';
import { PilotsWorkbenchFilter } from './models/pilots-workbench-filter';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {
  TimeframeFilterEnum,
  TimeframeFilterEnumDisplayNames,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/timeframes-filter.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InfoPilotModalComponent } from '../info-pilot-modal/info-pilot-modal.component';
import { InfoMissionModalComponent } from '../info-mission-modal/info-mission-modal.component';

@Component({
  selector: 'app-pilot-workbench',
  templateUrl: './pilot-workbench.component.html',
  styleUrls: ['./pilot-workbench.component.scss'],
  providers: [ListService],
})
export class PilotWorkbenchComponent implements OnInit, OnDestroy {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  FilterType = FilterType;
  TimeframeFilterEnum = TimeframeFilterEnum;
  data: PagedResultDto<LocationsDto> = {
    items: [],
    totalCount: 0,
  };

  layers = [
    { name: 'Base Layer', visible: true, icon: 'map' },
    { name: 'Satellite Layer', visible: false, icon: 'satellite' },
    { name: 'Points of Interest', visible: false, icon: 'place' },
  ];

  isFirstMissionRequest: boolean = true;
  isFirstPilotRequest: boolean = true;
  layerControl: boolean = false;
  jobId: string = '';
  customerName: string = '';
  projectName: string = '';
  portfolio: string = '';
  locationName: string = '';
  pilotName: string = '';
  capturefromDate: Date | null = null;
  capturetoDate: Date | null = null;
  fromDate: Date | null = null;
  toDate: Date | null = null;
  deliverableDueFromDate: Date | null = null;
  deliverableDueToDate: Date | null = null;

  response: boolean = false;
  isMissionFilter: boolean = false;
  isInvalid: boolean = false;

  missionsMarkers: FlyGuysDashboardMarkers[] = [];
  pilotsMarkers: FlyGuysDashboardMarkers[] = [];
  filters = { isPaginated: true } as GetPilotsForSourcingInput;

  locations: PagedResultDto<LocationsDto> = {
    items: [],
    totalCount: 0,
  };

  pilotsForSourcingData: PagedResultDto<PilotSourcingTableDto> = {
    items: [],
    totalCount: 0,
  };

  missions: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  experienceLevels: PagedResultDto<PilotExperienceLevelesDto> = {
    items: [],
    totalCount: 0,
  };

  priorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  };

  statuses: PagedResultDto<StatusDto> = {
    items: [],
    totalCount: 0,
  };

  customers: PagedResultDto<CustomersDto> = {
    items: [],
    totalCount: 0,
  };

  pilotsResult: PagedResultDto<PilotSourcingDto> = {
    items: [],
    totalCount: 0,
  };

  missionsData: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  statusResult: PagedResultDto<StatusMissionOrderDto> = {
    items: [],
    totalCount: 0,
  };

  equipments: EquipmentsDto[];
  availabilities: AvailabilityDto[];
  filteredEquipments: EquipmentsDto[];
  filterConfig: FilterConfig = MissionsPilotWorkbenchFilter;
  pilotFilterConfig: FilterConfig = PilotsWorkbenchFilter;
  filterConfiguration = [];
  statusesId = [];
  prioritiesId = [];
  equipmentIds = [];
  availabilityIds = [];
  badgesId = [];
  preferredContactStyles = [];
  sensorsType = [];
  minDate: Date;
  isMission: boolean = false;
  isPilot: boolean = false;
  isMissionDirty: boolean = false;
  isPilotDirty: boolean = false;
  firstCount: number = 0;
  missionUpdate: MissionUpdateStorage;

  searchForm: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  isFiltersHidden = true;
  statusOptions: any[] = [];
  selectedPilot: PilotSourcingDto;

  public page = 1;
  public totalCount = 0;
  public sorting = '';
  valuesMaxResultCount = [];
  MaxResultCount = 100;

  pilotForSourcingGridFilters: GetPilotSourcingFilters = {};
  pilotForSourcingGridExtraFilters: GetPilotSourcingExtraFilters = {
    infoChecked: true,
    isFromMissionDetailsRequested: false,
  };

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearchDto = {
    maxResultCount: 50000,
    skipCount: 0,
  };

  paginationSortingAndGlobalSearchPilots: PaginationSortingAndGlobalSearchDto = {
    skipCount: 0,
    maxResultCount: 50000,
  };

  currentUserId: string;

  customerDict: { [id: string]: string } = {};

  @ViewChild(MatExpansionPanel, { static: true }) matExpansionPanelElement: MatExpansionPanel;

  currentLocationFilter: string = '';

  formGroup: FormGroup;
  options: google.maps.places.AutocompletePrediction[];

  filtersLocations = { isPaginated: false } as GetLocationInput;

  predefinedStatuses = ['CustomerRequest', 'Readyforsourcing', 'PilotSourcing'];
  currentProgress = 0;
  lastFiltersApplied: MissionsGridFilters;
  readonly PAGE_SIZE = 150;
  currentMissionCounts = 0;
  missionSubs = new Subscription();

  constructor(
    private snackBar: MatSnackBar,
    public readonly pilotSourcingService: PilotSourcingService,
    private pilotExperienceLevelService: PilotExperienceLevelesService,
    public readonly list: ListService,
    public readonly service: MissionsService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    public loadingService: LoadingOverlayService,
    private dialog: MatDialog,
    private stateService: ConfigStateService,
    private equipmentsService: EquipmentsService,
    private spinner: NgxSpinnerService,
    private readonly pilotStateService: PilotStatesService,
    private readonly _fb: FormBuilder,
    private permissionService: PermissionService,
    public readonly prioritiesService: PrioritiesService,
    public readonly statusService: StatusService,
    public readonly customerService: CustomersService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
    public readonly locationService: LocationsService,
    public readonly localizationService: LocalizationService,
    private availabilityService: AvailabilityService,
    private badgeService: BadgesService,
    private httpWaitService: HttpWaitService,
  ) {
    this.minDate = new Date();
    this.formGroup = this._fb.group({
      address: '',
    });
    this.options = [];

    this.httpWaitService.addFilter([
      {
        method: 'POST',
        endpoint: '/api/missions-service/mission/list',
      },
    ]);
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.onStorageChange.bind(this));
  }

  ngOnInit() {
    window.addEventListener('storage', this.onStorageChange.bind(this));
    this.currentUserId = this.stateService.getDeep('currentUser.id');
    this.isFirstMissionRequest = true;
    this.getPriorities();
    this.getCustomerValues();
    this.getPilotExperienceLevelValues();
    this.getEquipments();
    this.getAvailabilityValues();
    this.getPilotBadges();
    this.getPilotStateValues();
    this.getLocationValues();
    this.callPilotsApiWithFilters();
    this.loadStatusesAndMissions();
    this.updateContactOptions();
    this.updateTimeframeOptions();
  }

  private fromJSONToMission(json: string): MissionUpdateStorage {
    return JSON.parse(json);
  }

  private fromJSONToLocation(json: string): MissionUpdateLocation {
    return JSON.parse(json);
  }

  onStorageChange(event: StorageEvent): void {
    if (event.key === 'missionData') {
      this.editMission();
    }
    if (event.key == 'missionDataLocation') {
      this.editLocation();
    }
  }

  private editMission(): void {
    const data = localStorage.getItem('missionData');
    if (data) {
      const missionUpdate: MissionUpdateStorage = this.fromJSONToMission(data);
      let missionIndex = this.missions.items.findIndex(x => x.id == missionUpdate.missionId);
      if (missionIndex !== -1) {
        this.missions.items[missionIndex].name = missionUpdate.missionName;
      }
    }
    localStorage.removeItem('missionData');
  }

  private editLocation(): void {
    const data = localStorage.getItem('missionDataLocation');
    let jobId: string;
    if (data) {
      const missionLocationUpdate: MissionUpdateLocation = this.fromJSONToLocation(data);
      let missionIndex = this.missions.items.findIndex(
        x => x.id == missionLocationUpdate.missionId,
      );
      if (missionIndex !== -1) {
        jobId = this.missions.items[missionIndex].jobId;
        this.missions.items[missionIndex].latitude = Number(missionLocationUpdate.latitude);
        this.missions.items[missionIndex].longitude = Number(missionLocationUpdate.longitude);
        this.missions.items[missionIndex].locationAddress = missionLocationUpdate.address;
      }
      this.map.updateMissionMarker(
        Number(jobId),
        Number(missionLocationUpdate.latitude),
        Number(missionLocationUpdate.longitude),
      );
    }
    localStorage.removeItem('missionDataLocation');
  }

  private getPriorities(): void {
    const query = {} as ABP.PageQueryParams;
    const industryFilter = { state: enumState.Enabled, isPaginated: false } as GetIndustryInput;

    this.prioritiesService
      .getList({
        ...query,
        ...industryFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.priorities = res;
        this.updatePriorities();
      });
  }

  private updateTimeframeOptions(): void {
    const dataTimeframesFilter = Object.keys(TimeframeFilterEnum)
      .filter(key => typeof TimeframeFilterEnum[key] === 'number')
      .map(key => ({
        id: TimeframeFilterEnum[key],
        description: this.localizationService.instant(
          TimeframeFilterEnumDisplayNames[TimeframeFilterEnum[key]],
        ),
      }));

    const conditionCaptureTime = this.filterConfig.conditions.find(c => c.column === 'captureDate');
    if (conditionCaptureTime) {
      conditionCaptureTime.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const condition = this.filterConfig.conditions.find(c => c.column === 'creationDate');
    if (condition) {
      condition.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const dueDateCondition = this.filterConfig.conditions.find(
      c => c.column === 'deliverableDueDate',
    );
    if (dueDateCondition) {
      dueDateCondition.options = dataTimeframesFilter.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }
  }

  private getLocationValues(): void {
    const query = {} as ABP.PageQueryParams;

    this.locationService
      .getList({
        ...query,
        ...this.filtersLocations,
        filterText: query.filter,
      })
      .subscribe(result => {
        this.locations = result;
      });
  }

  private loadStatusesAndMissions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'missionStatusId');

    if (condition) {
      this.statusMissionOrderService
        .getList({ maxResultCount: 1000, sorting: 'order' })
        .subscribe(res => {
          this.statusResult = res;
          condition.options = res.items.map(status => {
            return {
              id: status.statusEnum.toString(),
              description: status.statusDescription,
              selected: !!this.predefinedStatuses.find(x => x == status.code),
            };
          });

          let optionsSelected = condition.options.filter(x => (<any>x).selected);

          this.statusesId = optionsSelected.map(x => x.id);

          this.missionGridFilters.missionStatusId = this.statusesId;

          this.callApiWithFilters();
        });
    }
  }

  private getCustomerValues(): void {
    const query = {} as ABP.PageQueryParams;
    const customerFilter = { state: enumState.Enabled } as GetCustomerInput;

    this.customerService
      .getList({
        ...query,
        ...customerFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.customers = res;
        this.updateCustomers();
        for (let dep of this.customers.items) {
          this.customerDict[dep.id] = dep.name;
        }
      });
  }

  private updateCustomers(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'customerName');
    if (condition) {
      condition.options = this.customers.items.map(item => ({
        id: item.id,
        description: item.name,
      }));
    }
  }

  private updatePriorities(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'priorityId');
    if (condition) {
      condition.options = this.priorities.items.map(item => ({
        id: item.id,
        description: item.description,
      }));
    }
  }

  private getPilotExperienceLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const experienceLevelFilter = { state: enumState.Enabled } as GetPilotExperienceLevelInput;

    this.pilotExperienceLevelService
      .getList({
        ...query,
        ...experienceLevelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.experienceLevels = res;
        this.updateExperienceLevelOptions(res.items);
      });
  }

  private updateExperienceLevelOptions(data: any[]): void {
    const condition = this.pilotFilterConfig.conditions.find(
      c => c.column === 'pilotExperienceLevelId',
    );
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  getEquipments() {
    this.equipmentsService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.equipments = r.items;
      this.updateEquipmentsOptions(r.items);
      this.filteredEquipments = this.equipments.slice();
    });
  }

  private updateEquipmentsOptions(data: any[]): void {
    const condition = this.pilotFilterConfig.conditions.find(c => c.column === 'equipmentId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  getAvailabilityValues(): void {
    const query = {} as ABP.PageQueryParams;
    const availabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;

    this.availabilityService
      .getList({
        ...query,
        ...availabilityFilter,
        filterText: query.filter,
      })
      .subscribe(r => {
        this.availabilities = r.items;
        this.updateAvailabilitiesOptions(r.items);
      });
  }

  private updateAvailabilitiesOptions(data: any[]): void {
    const condition = this.pilotFilterConfig.conditions.find(c => c.column === 'availabilityId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  private getPilotBadges() {
    const input: GetBadgeInput = {
      maxResultCount: 1000,
      state: enumState.Enabled,
    };

    this.badgeService.getList(input).subscribe({
      next: (response: PagedResultDto<BadgesDto>) => {
        const activeBadges = response.items.filter(badge => badge.isActive);
        this.updateBadgeOptions(activeBadges);
      },
      error: err => console.log(err),
    });
  }

  private updateBadgeOptions(data: any[]): void {
    const condition = this.pilotFilterConfig.conditions.find(c => c.column === 'pilotBadgesId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private updateContactOptions(): void {
    const condition = this.pilotFilterConfig.conditions.find(
      c => c.column === 'preferredContactStyle',
    );
    if (condition) {
      condition.options = [
        { id: 'Email', description: 'Email' },
        { id: 'Text', description: 'Text' },
        { id: 'Phone Call', description: 'Phone Call' },
        { id: 'In-app Notifications', description: 'In-app Notifications' },
        { id: 'No Preference', description: 'No Preference' },
      ];
    }
  }

  private getPilotStateValues(): void {
    const query = {} as ABP.PageQueryParams;
    const pilotStateFilter = { state: enumState.Enabled } as GetPilotStateInput;

    this.pilotStateService
      .getList({
        ...query,
        ...pilotStateFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.updatePilotStateOptions(res.items);
      });
  }

  private updatePilotStateOptions(data: any[]): void {
    const condition = this.pilotFilterConfig.conditions.find(c => c.column === 'pilotStateId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }
  // We need this to ensure that the component app-grid-filters is re-rendered.
  // Otherwise we get weird behaviour, displaying previous filters in the summary.

  missionGridFilters: MissionsGridFilters = {};

  statusAndAssignedPermissions: StatusAndAssignedPermissions = {
    assignedToAnyone: true,
    inAnyStatus: false,
    assignedToMe: false,
    inTheTeam: false,
    allRecords: true,
  };

  private getMissionValues(pageSize?: number): Observable<PagedResultDto<MissionsDto>> {
    let showAssignedToAnyone = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.AssignedToAnyone',
    );
    let showInAnyStatus = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.InAnyStatus',
    );

    if (showAssignedToAnyone) {
      this.statusAndAssignedPermissions.assignedToAnyone = showAssignedToAnyone;
    }

    if (showInAnyStatus) {
      this.statusAndAssignedPermissions.inAnyStatus = showInAnyStatus;
    }

    if (pageSize) this.paginationSortingAndGlobalSearch.maxResultCount = pageSize;

    if (!this.isFirstMissionRequest && !this.isMission) {
      this.paginationSortingAndGlobalSearch.skipCount = this.missionsData.totalCount;
    }

    this.lastFiltersApplied = { ...this.missionGridFilters };

    return this.service.getMissionsGridData(
      this.missionGridFilters,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch,
    );
  }

  private getMissionValuesPaginated(
    pageSize: number,
    skipCount: number,
  ): Observable<PagedResultDto<MissionsDto>> {
    let showAssignedToAnyone = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.AssignedToAnyone',
    );
    let showInAnyStatus = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.InAnyStatus',
    );

    if (showAssignedToAnyone) {
      this.statusAndAssignedPermissions.assignedToAnyone = showAssignedToAnyone;
    }

    if (showInAnyStatus) {
      this.statusAndAssignedPermissions.inAnyStatus = showInAnyStatus;
    }

    this.paginationSortingAndGlobalSearch.maxResultCount = pageSize;
    this.paginationSortingAndGlobalSearch.skipCount = skipCount;

    return this.service.getMissionsGridData(
      this.lastFiltersApplied,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch,
    );
  }

  callApiWithFilters() {
    this.currentProgress = 0;
    this.updateSpinnerMapStatus();
    this.missionSubs.unsubscribe();

    this.missions = {
      items: [],
      totalCount: 0,
    };
    if (this.isFirstMissionRequest || this.isMissionFilter) this.spinner.show();
    this.getMissionValues(this.PAGE_SIZE).subscribe({
      next: (missionValues: PagedResultDto<MissionsDto>) => {
        if (missionValues) {
          this.response = true;
          this.isMissionFilter = false;
          if (missionValues.items.length == 0) {
            this.spinner.hide();
          }

          this.missions = missionValues;
          let missionsValuesFilter = this.missions.items.filter(
            x => x.locationAddress !== null && x.locationAddress !== '',
          );
          this.processMissionData(missionsValuesFilter);

          this.currentMissionCounts = this.missions.items.length;
          if (this.isFirstMissionRequest) {
            this.firstCount = this.missions.totalCount;
          }

          if (this.missions.totalCount > this.currentMissionCounts) {
            this.missionSubs = new Subscription();
            this.getMoreMissions();
          } else {
            this.currentProgress = 360;
            this.updateSpinnerMapStatus();
          }
        }
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  missionRequest() {
    if (this.missions?.totalCount == this.firstCount) {
      let missionsValuesFilter = this.missions.items.filter(
        x => x.locationAddress !== null && x.locationAddress !== '',
      );
      this.missionsData.items = missionsValuesFilter;
      this.missionsData.totalCount = this.missionsData.items.length;
    } else {
      this.spinner.hide();
      this.callApiWithFilters();
    }
  }

  getMoreMissions() {
    let totalMissions = this.missions.totalCount;
    let alreadyLoaded = this.currentMissionCounts;

    this.currentProgress = (alreadyLoaded * 360) / totalMissions;

    this.updateSpinnerMapStatus();

    this.missionSubs.add(
      this.getMissionValuesPaginated(this.PAGE_SIZE, this.currentMissionCounts).subscribe({
        next: async values => {
          this.currentMissionCounts += values.items?.length ?? 0;
          this.missions.items = this.missions.items.concat(values.items);

          let missionsValuesFilter = this.missions.items.filter(
            x => x.locationAddress !== null && x.locationAddress !== '',
          );
          this.acumulateMissionData(missionsValuesFilter);

          if (this.missions.totalCount > this.currentMissionCounts) {
            if (this.isFirstMissionRequest) {
              this.missionsData.items = missionsValuesFilter;
              this.missionsData.totalCount = this.missionsData.items.length;
            }
            this.getMoreMissions();
          } else {
            this.currentProgress = 360;
            this.isFirstMissionRequest = false;
          }
        },
        error: err => console.log(err),
      }),
    );
  }

  async processMissionData(missionsData: MissionsDto[]) {
    this.missionsMarkers = [];

    const locationCoordinatesMap = new Map<string, string>();
    for (const location of this.locations.items) {
      locationCoordinatesMap.set(location.id, location.gpsCoordinates);
    }

    await Promise.all(
      missionsData.map(async mission => {
        const locationId = mission.locationId;
        const coordinatesGps = locationCoordinatesMap.get(locationId);
        if (coordinatesGps && this.isValidCoordinates(coordinatesGps)) {
          const [lat, lng] = coordinatesGps.split(',').map(Number);
          mission.latitude = lat;
          mission.longitude = lng;
          const dashboardMark: FlyGuysDashboardMarkers = {
            jobId: Number(mission['jobId']),
            priority: mission.priorityDescription,
            missionName: mission.name,
            missionAddress: mission.locationAddress,
            coordinateLat: lat,
            coordinateLng: lng,
            pilot: mission.pilotName,
            assigned: true,
            pilotAddress: '',
            isPilot: false,
            customerName: mission.customerName,
            missionStatus: mission.missionOrderResume?.statusName,
          };
          this.missionsMarkers.push(dashboardMark);
        }
      }),
    );
    this.map.addMissionsMarker(this.missionsMarkers);
    if (this.isFirstMissionRequest) {
      this.spinner.hide();
    }
    this.response = false;
  }

  async acumulateMissionData(missionsData: MissionsDto[]) {
    this.missionsMarkers = [];
    this.map.clearMissionMarkers();
    this.map.clearMissionCluster();

    const locationCoordinatesMap = new Map<string, string>();
    for (const location of this.locations.items) {
      locationCoordinatesMap.set(location.id, location.gpsCoordinates);
    }

    if (!this.isFirstMissionRequest) {
      this.spinner.hide();
    }

    await Promise.all(
      missionsData.map(async mission => {
        const locationId = mission.locationId;
        const coordinatesGps = locationCoordinatesMap.get(locationId);
        if (coordinatesGps && this.isValidCoordinates(coordinatesGps)) {
          const [lat, lng] = coordinatesGps.split(',').map(Number);
          mission.latitude = lat;
          mission.longitude = lng;
          const dashboardMark: FlyGuysDashboardMarkers = {
            jobId: Number(mission['jobId']),
            priority: mission.priorityDescription,
            missionName: mission.name,
            missionAddress: mission.locationAddress,
            coordinateLat: lat,
            coordinateLng: lng,
            pilot: mission.pilotName,
            assigned: true,
            pilotAddress: '',
            isPilot: false,
          };
          this.missionsMarkers.push(dashboardMark);
        }
      }),
    );
    this.map.addMissionsMarker(this.missionsMarkers);
    // this.spinner.hide();
    this.response = false;
  }

  callPilotsApiWithFilters() {
    if (!this.isFirstPilotRequest) this.spinner.show();
    this.getPilotSourcingValues().subscribe({
      next: (response: PagedResultDto<PilotSourcingDto>) => {
        response?.items.forEach(resp => {
          if (resp.addressLat != null && resp.addressLng != null) {
            resp.hasCoordinates = true;
          } else {
            resp.hasCoordinates = false;
          }
        });
        this.pilotsResult = response;
        if (this.isFirstPilotRequest) {
          this.isFirstPilotRequest = false;
        }

        this.processPilotData(response.items);
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  private getPilotSourcingValues(): Observable<PagedResultDto<PilotSourcingDto>> {
    return this.pilotSourcingService.getPilotSourcingData(
      this.pilotForSourcingGridFilters,
      this.pilotForSourcingGridExtraFilters,
      this.paginationSortingAndGlobalSearchPilots,
    );
  }

  async processPilotData(pilotsData: PilotSourcingDto[]) {
    this.pilotsMarkers = [];
    this.map.clearPilotMarkers();
    this.map.clearPilotCluster();

    pilotsData.forEach(pilot => {
      if (pilot.hasCoordinates) {
        const dashboardMark: FlyGuysDashboardMarkers = {
          jobId: 0,
          priority: '',
          missionName: pilot.name,
          missionAddress: pilot.address,
          coordinateLat: pilot.addressLat,
          coordinateLng: pilot.addressLng,
          pilot: pilot.name,
          pilotAddress: pilot.address,
          assigned: false,
          isPilot: true,
          pilotId: pilot.id,
        };
        this.pilotsMarkers.push(dashboardMark);
      }
    });

    this.map.addPilotsMarker(this.pilotsMarkers);
    this.spinner.hide();
  }

  isValidCoordinates(coordinates) {
    const regex = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/;
    return regex.test(coordinates);
  }

  geocodeAddress(address: string): Promise<{ latitude: number; longitude: number }> {
    return new Promise(resolve => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          resolve({ latitude: location.lat(), longitude: location.lng() });
        } else {
          resolve({ latitude: 0, longitude: 0 });
        }
      });
    });
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    } else if (condition.type === FilterType.Boolean) {
      return value?.toLowerCase() == 'true' ? 'Yes' : 'No';
    }
    return value;
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.callApiWithFilters();
  }

  /**
   * Handles the removal of a geofence
   * @param geofence FlyguysMapGeofence
   */

  formatOption(option: any): string {
    return option.description || option;
  }

  closeSidebar() {
    this.layerControl = false;
    this.map.showLayersButton();
  }

  handleMenu(event: boolean) {
    this.layerControl = event;
    this.map.hideLayersButton();
  }

  onCheckboxChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'status') {
      if (option['selected'] == true) {
        const statusId = option['id'];
        this.statusesId.push(statusId);
        this.missionGridFilters.missionStatusId = this.statusesId;
      } else {
        const newStatusesId = this.statusesId.filter(id => id !== option['id']);
        this.statusesId = newStatusesId;
        this.missionGridFilters.missionStatusId = this.statusesId;
      }
      this.isMission = true;
      this.isMissionDirty = true;
    }
    if (filter.columnDisplayName.toLocaleLowerCase() == 'priority') {
      if (option['selected'] == true) {
        const priority = option['id'];
        this.prioritiesId.push(priority);
        this.missionGridFilters.priorityId = this.prioritiesId;
      } else {
        const newPrioritiesId = this.prioritiesId.filter(id => id !== option['id']);
        this.prioritiesId = newPrioritiesId;
        this.missionGridFilters.priorityId = this.prioritiesId;
      }
      this.isMission = true;
      this.isMissionDirty = true;
    }
  }

  applyCustomerFilter(customerFilter: string) {
    if (customerFilter !== '') {
      const customer = [customerFilter];
      this.missionGridFilters.customerName = customer;
    } else {
      this.missionGridFilters.customerName = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  applyProjectFilter(projectId: string) {
    if (projectId !== '') {
      const project = [projectId];
      this.missionGridFilters['projectName'] = project;
    } else {
      this.missionGridFilters['projectName'] = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  onDateInputChange() {
    if (this.capturefromDate && this.capturetoDate) {
      const fromDateStr = this.formatDateToString(this.capturefromDate);
      const toDateStr = this.formatDateToString(this.capturetoDate);
      const dateRangeStr = `${fromDateStr} - ${toDateStr}`;
      this.missionGridFilters.captureDate = [...this.missionGridFilters.captureDate, dateRangeStr];
      this.isMission = true;
      this.isMissionDirty = true;
      this.updateDateRangeValue(this.capturefromDate, this.capturetoDate);
    }
  }

  updateDateRangeValue(fromDate: Date, toDate: Date): void {
    if (fromDate && toDate && fromDate > toDate) {
      this.isInvalid = true;
      this.snackBar.open('The "From" date cannot be after the "To" date.', 'Close', {
        duration: 3000,
      });
      return;
    } else {
      this.isInvalid = false;
    }
  }

  resetDate({ column, id }: FilterCondition) {
    if (column === 'captureDate') {
      this.capturefromDate = null;
      this.capturetoDate = null;
      if (id === TimeframeFilterEnum.CustomDateRange.toString())
        this.missionGridFilters.captureDate = [];
      else {
        this.missionGridFilters.captureDate = [id];
        this.isMission = true;
        this.isMissionDirty = true;
      }
    } else if (column === 'creationDate') {
      this.fromDate = null;
      this.toDate = null;
      if (id === TimeframeFilterEnum.CustomDateRange.toString())
        this.missionGridFilters.creationDate = [];
      else {
        this.missionGridFilters.creationDate = [id];
        this.isMission = true;
        this.isMissionDirty = true;
      }
    } else if (column === 'deliverableDueDate') {
      this.deliverableDueFromDate = null;
      this.deliverableDueToDate = null;
      if (id === TimeframeFilterEnum.CustomDateRange.toString())
        this.missionGridFilters.deliverableDueDate = [];
      else {
        this.missionGridFilters.deliverableDueDate = [id];
        this.isMission = true;
        this.isMissionDirty = true;
      }
    }
  }

  onCreationDateInputChange() {
    if (this.fromDate && this.toDate) {
      const fromDateStr = this.formatDateToString(this.fromDate);
      const toDateStr = this.formatDateToString(this.toDate);
      const dateRangeStr = `${fromDateStr} - ${toDateStr}`;
      this.missionGridFilters.creationDate = [
        ...this.missionGridFilters.creationDate,
        dateRangeStr,
      ];
      this.isMission = true;
      this.isMissionDirty = true;
      this.updateDateRangeValue(this.fromDate, this.toDate);
    }
  }

  onDeliverableDueInputChange() {
    if (this.deliverableDueFromDate && this.deliverableDueToDate) {
      const fromDateStr = this.formatDateToString(this.deliverableDueFromDate);
      const toDateStr = this.formatDateToString(this.deliverableDueToDate);
      const dateRangeStr = `${fromDateStr} - ${toDateStr}`;
      this.missionGridFilters.deliverableDueDate = [
        ...this.missionGridFilters.deliverableDueDate,
        dateRangeStr,
      ];
      this.isMission = true;
      this.isMissionDirty = true;
      this.updateDateRangeValue(this.deliverableDueFromDate, this.deliverableDueToDate);
    }
  }

  applyCreationDateFilter(from: Date, to: Date) {
    if (from && to) {
      const fromDateStr = this.formatDateToString(from);
      const toDateStr = this.formatDateToString(to);
      const dateRangeStr = `${fromDateStr} - ${toDateStr}`;
      this.missionGridFilters.creationDate = [dateRangeStr];
      this.isMission = true;
      this.isMissionDirty = true;
    }
  }

  applyCaptureDateFilter(from: Date, to: Date) {
    if (from && to) {
      const fromDateStr = this.formatDateToString(from);
      const toDateStr = this.formatDateToString(to);
      const dateRangeStr = `${fromDateStr} - ${toDateStr}`;
      this.missionGridFilters.captureDate = [dateRangeStr];
      this.isMission = true;
      this.isMissionDirty = true;
    }
  }

  formatDateToString(date: Date): string {
    if (!date) return '';
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  }

  applyJobIdFilter(jobId: string) {
    if (jobId !== '') {
      const jobCode = [jobId];
      this.missionGridFilters.jobId = jobCode;
    } else {
      this.missionGridFilters.jobId = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  applyPortfolioFilter(portfolio: string) {
    if (portfolio !== '') {
      const portfolioName = [portfolio];
      this.missionGridFilters['portfolioName'] = portfolioName;
    } else {
      this.missionGridFilters['portfolioName'] = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  applyLocationFilter(location: string) {
    if (location !== '') {
      const locationName = [location];
      this.missionGridFilters.locationName = locationName;
    } else {
      this.missionGridFilters.locationName = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  applypilotNameFilter(pilot: string) {
    if (pilot !== '') {
      const pilotName = [pilot];
      this.missionGridFilters.pilotName = pilotName;
    } else {
      this.missionGridFilters.pilotName = [];
    }
    this.isMission = true;
    this.isMissionDirty = true;
  }

  onCheckboxEquipmentChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'equipment') {
      if (option['selected']) {
        const equipmentId = option['id'];
        this.equipmentIds.push(equipmentId);
        this.pilotForSourcingGridFilters.equipmentId = this.equipmentIds;
      } else {
        const newPrioritiesId = this.equipmentIds.filter(id => id !== option['id']);
        this.equipmentIds = newPrioritiesId;
        this.pilotForSourcingGridFilters.equipmentId = this.equipmentIds;
      }
      this.isPilot = true;
      this.isPilotDirty = true;
    }
  }

  onCheckboxAvailabilityChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'availability') {
      if (option['selected']) {
        const availabilityId = option['id'];
        const availabilityDescp = option['description'];
        this.availabilityIds.push(availabilityId);
        this.pilotForSourcingGridFilters.availabilityId = this.availabilityIds;
      } else {
        const newPrioritiesId = this.availabilityIds.filter(id => id !== option['id']);
        this.availabilityIds = newPrioritiesId;
        this.pilotForSourcingGridFilters.availabilityId = this.availabilityIds;
      }
      this.isPilot = true;
      this.isPilotDirty = true;
    }
  }

  onCheckboxPreferredContactStyleIdChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'preferred contact style') {
      if (option['selected']) {
        const preferredContactStyleId = option['id'];
        this.preferredContactStyles.push(preferredContactStyleId);
        this.pilotForSourcingGridFilters['preferredContactStyle'] = this.preferredContactStyles;
      } else {
        const preferredContactStyleId = this.preferredContactStyles.filter(
          id => id !== option['id'],
        );
        this.preferredContactStyles = preferredContactStyleId;
        this.pilotForSourcingGridFilters['preferredContactStyle'] = this.preferredContactStyles;
      }
      this.isPilot = true;
      this.isPilotDirty = true;
    }
  }

  onCheckboxSensorsTypeIdChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'sensors type') {
      if (option['selected']) {
        const sensorsTypeId = option['id'];
        this.sensorsType.push(sensorsTypeId);
        this.pilotForSourcingGridFilters['sensorsType'] = this.sensorsType;
      } else {
        const sensorsTypeId = this.sensorsType.filter(id => id !== option['id']);
        this.sensorsType = sensorsTypeId;
        this.pilotForSourcingGridFilters['sensorsType'] = this.sensorsType;
      }
      this.isPilot = true;
      this.isPilotDirty = true;
    }
  }

  onCheckboxBadgesChange(filter: FilterCondition, option: string) {
    if (filter.columnDisplayName.toLowerCase() == 'badges') {
      if (option['selected']) {
        const badgeId = option['id'];
        this.badgesId.push(badgeId);
        this.pilotForSourcingGridFilters.pilotBadgesId = this.badgesId;
      } else {
        const newBadgeIdsId = this.badgesId.filter(id => id !== option['id']);
        this.badgesId = newBadgeIdsId;
        this.pilotForSourcingGridFilters.pilotBadgesId = this.badgesId;
      }
      this.isPilot = true;
      this.isPilotDirty = true;
    }
  }

  applyPilotNameFilter(name: string) {
    const pilotName = [name];
    this.pilotForSourcingGridFilters['name'] = pilotName;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  applyZipCodeFilter(zipCode: string) {
    const zip = [zipCode];
    this.pilotForSourcingGridFilters['addressZipCode'] = zip;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  applyCountryFilter(country: string) {
    const pilotCountry = [country];
    this.pilotForSourcingGridFilters['addressCountry'] = pilotCountry;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  applyAddressStateFilter(state: string) {
    const addressState = [state];
    this.pilotForSourcingGridFilters['addressState'] = addressState;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  applyAddressCityFilter(city: string) {
    const addressCity = [city];
    this.pilotForSourcingGridFilters['addressCity'] = addressCity;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  toggle(event: MatSlideToggleChange) {
    if (event.checked) {
      const isPreferred = ['True'];
      this.pilotForSourcingGridFilters.isPreferred = isPreferred;
    } else {
      const isPreferred = [];
      this.pilotForSourcingGridFilters.isPreferred = isPreferred;
    }
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  applyAddressFilter(address: string) {
    const addressFilter = [address];
    this.pilotForSourcingGridFilters['address'] = addressFilter;
    this.isPilot = true;
    this.isPilotDirty = true;
  }

  clearAllFilter() {
    if (this.isPilotDirty || this.isMissionDirty) this.spinner.show();
    //Missions filters

    this.filterConfig.conditions.forEach(cond => {
      if (cond.id && cond.column.toLowerCase().includes('date')) cond.id = undefined;
      if (cond?.options) {
        cond.options.forEach(opt => (opt['selected'] = false));
      }
    });
    this.jobId = '';
    this.customerName = '';
    this.projectName = '';
    this.portfolio = '';
    this.locationName = '';
    this.pilotName = '';
    this.capturefromDate = null;
    this.capturetoDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.deliverableDueFromDate = null;
    this.deliverableDueToDate = null;
    this.missionGridFilters.captureDate = [];
    this.missionGridFilters.creationDate = [];
    this.missionGridFilters.deliverableDueDate = [];
    this.missionGridFilters.customerName = [];
    this.missionGridFilters.jobId = [];
    this.missionGridFilters.locationName = [];
    this.missionGridFilters.missionName = [];
    this.missionGridFilters.missionStatusId = [];
    this.missionGridFilters.pilotName = [];
    this.missionGridFilters['portfolioName'] = [];
    this.missionGridFilters.priorityId = [];
    this.missionGridFilters['projectName'] = [];
    this.prioritiesId = [];
    this.statusesId = [];
    this.isMission = false;
    if (this.isMissionDirty) {
      this.map.clearMissionMarkers();
      this.map.clearMissionCluster();
      this.missionRequest();
    }
    this.isMissionDirty = false;

    //Pilots Filters
    this.pilotFilterConfig.conditions.forEach(cond => {
      if (cond?.options) {
        cond.options.forEach(opt => (opt['selected'] = false));
      }
    });

    this.pilotForSourcingGridFilters.isPreferred = [];
    this.pilotForSourcingGridFilters['addressState'] = [];
    this.pilotForSourcingGridFilters['addressCountry'] = [];
    this.pilotForSourcingGridFilters['name'] = [];
    this.pilotForSourcingGridFilters.equipmentId = [];
    this.pilotForSourcingGridFilters.availabilityId = [];
    this.pilotForSourcingGridFilters.pilotBadgesId = [];
    this.pilotForSourcingGridFilters['preferredContactStyle'] = [];
    this.pilotForSourcingGridFilters['addressZipCode'] = [];
    this.pilotForSourcingGridFilters['address'] = [];
    this.pilotForSourcingGridFilters['addressCity'] = [];
    if (this.isPilotDirty) {
      this.map.clearPilotMarkers();
      this.map.clearPilotCluster();
      this.callPilotsApiWithFilters();
    }
    this.isPilot = false;
    this.isPilotDirty = false;
  }

  applyFilters() {
    this.isFirstMissionRequest = false;
    this.isFirstPilotRequest = false;
    if (!this.isMission && this.isPilot) {
      this.response = true;
    } else {
      this.response = false;
    }

    if (this.isMission) {
      this.map.clearMissionMarkers();
      this.map.clearMissionCluster();
      this.isMission = false;
      this.isMissionFilter = true;
      this.callApiWithFilters();
    }
    if (this.isPilot) {
      this.map.clearPilotMarkers();
      this.map.clearPilotCluster();
      this.callPilotsApiWithFilters();
      this.isPilot = false;
    }
  }

  handleMarkerPilotClick(id: string): void {
    let pilot = this.pilotsResult.items.find(x => x.id == id);
    if (pilot) {
      const pilotData: pilotData = {
        coordinateLat: pilot.addressLat,
        coordinateLng: pilot.addressLng,
        pilotName: pilot.name,
        pilotAddress: pilot.address,
        pilotId: pilot.id,
      };
      if (pilotData) {
        this.dialog.open(InfoPilotModalComponent, {
          width: '330px',
          data: pilotData,
        });
      }
    }
  }

  handleMarkerMissionClick(jobId: number): void {
    let mission = this.missions.items.find(x => x.jobId == String(jobId));
    if (mission) {
      const missionData: missionData = {
        jobId: Number(mission?.jobId),
        missionLat: mission?.latitude,
        missionLng: mission?.longitude,
        missionName: mission?.name,
        missionAddress: mission?.locationAddress,
        priority: '',
        missionStatus: mission?.missionOrderResume?.statusName,
        customerName: mission?.customerName,
      };
      if (missionData) {
        this.dialog.open(InfoMissionModalComponent, {
          width: '330px',
          data: missionData,
        });
      }
    }
  }

  private updateSpinnerMapStatus(): void {
    let spinner = document.getElementById('spinner');
    let ctx = (<any>spinner).getContext('2d');
    let width = (<any>spinner).width;
    let height = (<any>spinner).height;
    let new_degrees = 0;
    let difference = 0;
    let color = '#BC5A2E';
    let bgcolor = '#E9ECEF';
    let text;
    let animation_loop, redraw_loop;

    let var180 = 180;
    let var100 = 55;
    let limitText = 100;
    let var30 = 25;
    let font50 = '30px arial';

    function init(ngContext: any) {
      ctx.clearRect(0, 0, width, height);

      ctx.beginPath();
      ctx.strokeStyle = bgcolor;
      ctx.lineWidth = var30;
      ctx.arc(width / 2, width / 2, var100, 0, Math.PI * 2, false);
      ctx.stroke();
      let radians = (ngContext.currentProgress * Math.PI) / var180;

      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.lineWidth = var30;
      ctx.arc(
        width / 2,
        height / 2,
        var100,
        0 - (90 * Math.PI) / var180,
        radians - (90 * Math.PI) / var180,
        false,
      );
      ctx.stroke();
      ctx.fillStyle = color;
      ctx.font = font50;
      text = Math.floor((ngContext.currentProgress / 360) * limitText) + '%';
      let text_width = ctx.measureText(text).width;
      ctx.fillText(text, width / 2 - text_width / 2, height / 2 + 15);
    }

    function draw(ngContext: any) {
      if (typeof animation_loop != undefined) clearInterval(animation_loop);

      new_degrees = 360;
      difference = new_degrees - ngContext.currentProgress;
      animation_loop = setInterval(() => animate_to(ngContext), 10000 / difference);
    }

    function animate_to(ngContext: any) {
      if (ngContext.currentProgress == new_degrees) {
        clearInterval(animation_loop);
      }

      init(ngContext);
    }

    draw(this);
  }
}
