import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { pilotData } from '@flyguys/map';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-infoPilotModal-modal',
  templateUrl: './info-pilot-modal.component.html',
  styleUrls: ['./info-pilot-modal.component.scss'],
})
export class InfoPilotModalComponent implements OnInit {
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: pilotData,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.loading = true;
    if (this.data) {
      this.spinner.hide();
      this.loading = false;
    }
  }
}
