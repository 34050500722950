import { Component, OnInit, ViewChild } from '@angular/core';
import { PilotSourcingDto, PilotSourcingTableDto } from '../../models/pilot-sourcing-dto';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  enumState,
  EnumStateOptions,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/shared';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  PilotExperienceLevelesService,
  PilotStatesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import {
  GetAvailabilitiesInput,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetPilotExperienceLevelInput,
  GetPilotStateInput,
  GetSensorTypesInput,
  PilotExperienceLevelesDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import {
  ABP,
  ConfigStateService,
  ListService,
  LocalizationService,
  PagedResultDto,
} from '@abp/ng.core';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { GetPilotsForSourcingInput } from '../../models/pilot-for-sorucing-request';
import { Observable, firstValueFrom } from 'rxjs';
import { PilotSourcingService } from '../../../services/pilot-sourcing.service';
import { LoadingOverlayService } from '../../../services/loading/loading.service';
import { MatDialog } from '@angular/material/dialog';
import {
  BadgesDto,
  EquipmentsDto,
  type GetBadgeInput,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  BadgesService,
  EquipmentsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import {
  GetPilotSourcingExtraFilters,
  GetPilotSourcingFilters,
} from '../../models/get-pilot-sourcing-filters-dto';
import { PaginationSortingAndGlobalSearchDto } from '../../models/pagination-sorting-and-global-search-dto';
import { NgxSpinnerService } from 'ngx-spinner';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { PilotInfoComponent } from '../pilot-info/pilot-info.component';
import { PilotFinderSourcingFilterColumns } from '../../models/pilot-finder-sourcing-filter-columns';
import { FlyguysGeofenceComponent, FlyguysMapGeofence } from '@flyguys/geofence';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ToasterService } from '@abp/ng.theme.shared';
import { GoogleMapsService } from '@flyguys/services';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NotificationsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import {
  AvailabilityService,
  SensorTypesService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { AssignBadgeModalComponent } from '../assign-badge-modal/assign-badge-modal.component';
import { RevokeBadgeModalComponent } from '../revoke-badge-modal/revoke-badge-modal.component';
import { BadgePilotData } from '../assign-badge-modal/models/badge-pilot-data';
import { BadgeSelectionDto } from '../assign-badge-modal/models/badge-selection-dto';
import { PilotBadgeService } from '../../../../../../pilots-service/src/lib/proxy/pilots-service/controllers/relationals';
import {
  BulkAssignBadgesDto,
  BulkBadgeSelectionDto,
  BulkPilotSelectionDto,
  BulkRevokeBadgesDto,
  PilotBadgeDto,
} from '../../../../../../pilots-service/src/lib/proxy/pilots-service/relationals';
import { SelectionType } from '@swimlane/ngx-datatable';
import { BulkAssignBadgeModalComponent } from '../assign-badge-modal/bulk-assign-badge-modal.component';
import { BulkRevokeBadgeModalComponent } from '../revoke-badge-modal/bulk-revoke-badge-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ProgressionLevel } from '../assign-badge-modal/models/badge-progression-enum';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'app-pilot-finder',
  templateUrl: './pilot-finder.component.html',
  styleUrls: ['./pilot-finder.component.scss'],
  providers: [ListService],
})
export class PilotFinderComponent implements OnInit {
  @ViewChild(FlyguysGeofenceComponent) map: FlyguysGeofenceComponent;

  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';
  readonly maxDistance: number = 500;
  filters = { isPaginated: true } as GetPilotsForSourcingInput;

  pilotsForSourcingData: PagedResultDto<PilotSourcingDto> = {
    items: [],
    totalCount: 0,
  };

  states = EnumStateOptions;

  experienceLevels: PagedResultDto<PilotExperienceLevelesDto> = {
    items: [],
    totalCount: 0,
  };

  equipments: EquipmentsDto[];
  filteredEquipments: EquipmentsDto[];

  searchForm: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  notificationForm: FormGroup;

  pilotsForSMS: PilotSourcingDto[] = [];

  isFiltersHidden = true;
  isModalBusy = false;
  isModalOpen = false;
  statusOptions: any[] = [];
  selectedPilot: PilotSourcingDto;

  public page = 1;
  public totalCount = 0;
  public sorting = '';
  valuesMaxResultCount = [];
  MaxResultCount = 100;

  clientMaxResultCount = 10;
  pageNumber = 0;

  pilotForSourcingGridFilters: GetPilotSourcingFilters = {};
  pilotForSourcingGridExtraFilters: GetPilotSourcingExtraFilters = {
    infoChecked: true,
    isFromMissionDetailsRequested: false,
  };
  filterConfig: FilterConfig = PilotFinderSourcingFilterColumns;

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearchDto = {
    skipCount: 0,
    maxResultCount: 10,
  };

  currentUserId: string;

  @ViewChild(MatExpansionPanel, { static: true }) matExpansionPanelElement: MatExpansionPanel;

  currentLocationFilter: string = '';

  formGroup: FormGroup;
  options: google.maps.places.AutocompletePrediction[];

  availabilitiesDict: { [id: string]: string } = {};
  sensorTypesDict: { [id: string]: string } = {};

  selectedPilots: PilotSourcingDto[] = [];
  selectionType = SelectionType;
  selectAll: boolean;
  changingPageMaxResoultCount: boolean = false;

  constructor(
    public readonly pilotSourcingService: PilotSourcingService,
    private pilotExperienceLevelService: PilotExperienceLevelesService,
    public readonly list: ListService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    public loadingService: LoadingOverlayService,
    private dialog: MatDialog,
    private stateService: ConfigStateService,
    private equipmentsService: EquipmentsService,
    private spinner: NgxSpinnerService,
    private readonly pilotStateService: PilotStatesService,
    private readonly toaster: ToasterService,
    private readonly _fb: FormBuilder,
    private readonly _googleMapService: GoogleMapsService,
    private readonly notificationService: NotificationsService,
    private pilotService: PilotService,
    private availabilityService: AvailabilityService,
    private pilotBadgeService: PilotBadgeService,
    private badgeService: BadgesService,
    private sensorTypesService: SensorTypesService,
    public readonly identityUserService: IdentityUserService,
    private localizationService: LocalizationService,
  ) {
    this.formGroup = this._fb.group({
      address: '',
    });
    this.options = [];
  }

  ngOnInit() {
    this.currentUserId = this.stateService.getDeep('currentUser.id');
    this.initiateAutocomplete();
    this.getPageAttributeValues();
    this.getMaxCountAttribute();
    this.getPilotExperienceLevelValues();
    this.getPilotBadges();
    this.getEquipments();
    this.getPilotStateValues();
    this.getAvailabilityValues();
    this.callApiWithFilters();
    this.updateContactOptions();
    this.getSensorsTypeValues();
  }

  onSelect({ selected }) {
    if (selected.length === 0) {
      this.ifBulkActionWasExecutedUncheckMissions();
      return;
    }

    this.selectedPilots.splice(0, this.selectedPilots.length);
    this.selectedPilots.push(...selected);

    if (this.selectedPilots.length == this.pilotsForSourcingData?.items?.length) {
      this.selectAll = true;
    }
  }

  ifBulkActionWasExecutedUncheckMissions() {
    if (this.selectedPilots?.length > 0) {
      this.selectedPilots = new Array();
      this.selectAll = false;
    }
  }

  handleOnBulkAssign() {
    this.loadingService.showOverlay();

    const pilotIds = this.selectedPilots?.map(x => x.id);

    this.pilotBadgeService.getCurrentBadgesForPilots(pilotIds).subscribe({
      next: res => {
        this.loadingService.hideOverlay();

        const modalData = {
          currentBadges: res,
          selectedPilots: pilotIds,
        };

        const dialogRef = this.dialog.open(BulkAssignBadgeModalComponent, {
          disableClose: true,
          width: '900px',
          panelClass: 'custom-modalbox',
          data: modalData,
        });

        dialogRef.afterClosed().subscribe({
          next: (selectedBadges: BadgeSelectionDto[]) => {
            if (!selectedBadges) return;

            let badgeAssignation: BulkAssignBadgesDto = {
              badges: selectedBadges.map(
                x =>
                  <BulkBadgeSelectionDto>{
                    badgeId: x.badgeId,
                    badgeDescription: x.badgeName,
                    progressionLevel: x.progressionLevel,
                  },
              ),
              pilots: this.selectedPilots.map(
                x => <BulkPilotSelectionDto>{ pilotId: x.id, pilotDescription: x.name },
              ),
            };

            this.pilotBadgeService.bulkAssignation(badgeAssignation).subscribe({
              next: res => {
                this.callApiWithFilters();

                this.toaster.success(
                  `A total of ${badgeAssignation.badges.length} ${
                    badgeAssignation.badges.length > 1 ? 'badges were' : 'badge was'
                  } assigned successfully to ${pilotIds.length} selected ${
                    pilotIds.length > 1 ? 'pilots' : 'pilot'
                  }.`,
                );

                this.ifBulkActionWasExecutedUncheckMissions();
              },
              error: erro => console.error(erro),
            });
          },
          error: error => console.error('Error in dialog:', error),
        });
      },
      error: err => {
        this.loadingService.hideOverlay();
        console.error(err);
      },
    });
  }

  handleOnBulkRevoke() {
    this.loadingService.showOverlay();

    const pilotIds = this.selectedPilots?.map(x => x.id);

    this.pilotBadgeService.getCurrentBadgesForPilots(pilotIds).subscribe({
      next: res => {
        this.loadingService.hideOverlay();

        const modalData = {
          currentBadges: res,
          selectedPilots: pilotIds,
        };

        const dialogRef = this.dialog.open(BulkRevokeBadgeModalComponent, {
          disableClose: true,
          width: '900px',
          panelClass: 'custom-modalbox',
          data: modalData,
        });

        dialogRef.afterClosed().subscribe({
          next: (selectedBadges: string[]) => {
            if (!selectedBadges) return;

            let badgeRevokation: BulkRevokeBadgesDto = {
              badgeIds: selectedBadges,
              pilotIds: this.selectedPilots.map(x => x.id),
            };

            this.pilotBadgeService.bulkRevokation(badgeRevokation).subscribe({
              next: res => {
                this.callApiWithFilters();

                this.toaster.success(
                  `A total of ${badgeRevokation.badgeIds.length} ${
                    badgeRevokation.badgeIds.length > 1 ? 'badges were' : 'badge was'
                  } revoked successfully from ${pilotIds.length} selected ${
                    pilotIds.length > 1 ? 'pilots' : 'pilot'
                  }.`,
                );

                this.ifBulkActionWasExecutedUncheckMissions();
              },
              error: erro => console.error(erro),
            });
          },
          error: error => console.error('Error in dialog:', error),
        });
      },
      error: err => {
        this.loadingService.hideOverlay();
        console.error(err);
      },
    });
  }

  private getPageAttributeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
      });
  }

  private getMaxCountAttribute(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      )
      .subscribe(res => {
        let attribute = res.items.find(_ => true);
        this.MaxResultCount = parseInt(attribute.description);
      });
  }

  private refreshFilterConfig() {
    this.filterConfig = { ...this.filterConfig };
  }

  callApiWithFilters() {
    this.spinner.show();
    this.getPilotSourcingValues().subscribe({
      next: (response: PagedResultDto<PilotSourcingDto>) => {
        this.pilotsForSourcingData = response;
        this.spinner.hide();
        this.changingPageMaxResoultCount = false;
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  private getPilotSourcingValues(): Observable<PagedResultDto<PilotSourcingDto>> {
    return this.pilotSourcingService.getPilotSourcingData(
      this.pilotForSourcingGridFilters,
      this.pilotForSourcingGridExtraFilters,
      this.paginationSortingAndGlobalSearch,
    );
  }

  private getPilotExperienceLevelValues(): void {
    const query = {} as ABP.PageQueryParams;
    const experienceLevelFilter = { state: enumState.Enabled } as GetPilotExperienceLevelInput;

    this.pilotExperienceLevelService
      .getList({
        ...query,
        ...experienceLevelFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.experienceLevels = res;
        this.updateExperienceLevelOptions(res.items);
      });
  }

  private getPilotBadges() {
    const input: GetBadgeInput = {
      maxResultCount: 1000,
      state: enumState.Enabled,
    };

    this.badgeService.getList(input).subscribe({
      next: (response: PagedResultDto<BadgesDto>) => {
        const activeBadges = response.items.filter(badge => badge.isActive);
        this.updateBadgeOptions(activeBadges);
      },
      error: err => console.log(err),
    });
  }

  private updateExperienceLevelOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotExperienceLevelId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  getEquipments() {
    this.equipmentsService.getList({ maxResultCount: 100 }).subscribe(r => {
      this.equipments = r.items;
      this.updateEquipmentsOptions(r.items);
      this.filteredEquipments = this.equipments.slice();
    });
  }

  private updateEquipmentsOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'equipmentId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private getPilotStateValues(): void {
    const query = {} as ABP.PageQueryParams;
    const pilotStateFilter = { state: enumState.Enabled } as GetPilotStateInput;

    this.pilotStateService
      .getList({
        ...query,
        ...pilotStateFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.updatePilotStateOptions(res.items);
      });
  }

  private updatePilotStateOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotStateId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.pilotForSourcingGridFilters[updatedCondition.column as keyof GetPilotSourcingFilters] =
          updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.ifBulkActionWasExecutedUncheckMissions();
    this.callApiWithFilters();
  }

  onGlobalSearch() {
    this.paginationSortingAndGlobalSearch.filter = this.searchForm.get('searchInput')?.value.trim();

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.ifBulkActionWasExecutedUncheckMissions();
    this.callApiWithFilters();
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    } else if (condition.type === FilterType.Boolean) {
      return value?.toLowerCase() == 'true' ? 'Yes' : 'No';
    }
    return value;
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    this.pilotForSourcingGridFilters[column as keyof GetPilotSourcingFilters] = [];

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.ifBulkActionWasExecutedUncheckMissions();
    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    for (let condition of this.filterConfig.conditions) {
      this.pilotForSourcingGridFilters[condition.column as keyof GetPilotSourcingFilters] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.currentLocationFilter = '';
    this.pilotForSourcingGridExtraFilters.location = null;
    this.pilotForSourcingGridExtraFilters.radius = null;
    this.formGroup.get('address').enable();
    this.formGroup.get('address').setValue('');

    this.searchForm.get('searchInput')?.setValue('');
    this.paginationSortingAndGlobalSearch.filter = '';

    this.map.clearGeofences();

    this.ifBulkActionWasExecutedUncheckMissions();
    this.callApiWithFilters();
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;
      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.callApiWithFilters();
    }
  }

  onBeforePageSizeChange() {
    this.changingPageMaxResoultCount = true;
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };

    if (!this.changingPageMaxResoultCount) {
      if (this.selectAll) {
        this.ifBulkActionWasExecutedUncheckMissions();
      }
      this.callApiWithFilters();
    }
  }

  displayPilotProfile(id: string): void {
    const ref = this.dialog.open(PilotInfoComponent, {
      data: { id },
      width: '90%',
      height: '90%',
      disableClose: true,
      position: { top: '50px' },
    });

    ref.afterClosed().subscribe({
      next: () => {
        this.callApiWithFilters();
      },
    });
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.callApiWithFilters();
  }

  onInfoCheckedTurn() {
    if (!this.pilotForSourcingGridExtraFilters.infoChecked)
      this.pilotForSourcingGridExtraFilters.infoChecked = null;

    this.ifBulkActionWasExecutedUncheckMissions();
    this.callApiWithFilters();
  }

  handleRemoveGeofence(geofence: FlyguysMapGeofence) {
    this.formGroup.get('address').enable();
    this.formGroup.get('address').setValue('');

    this.currentLocationFilter = '';
    this.pilotForSourcingGridExtraFilters.location = null;
    this.pilotForSourcingGridExtraFilters.radius = null;

    this.callApiWithFilters();

    this.matExpansionPanelElement.close();
  }

  handleSaveChanges(): void {
    if (this.map.geofences.length > 0) {
      const currentMiles = this.map.geofences[0].circle.getRadius() * 0.000621371192;
      const geofenceRadiusInMiles = currentMiles > 500 ? '500.0' : currentMiles.toFixed(1);
      const geofenceLat = this.map.geofences[0].circle.getCenter()?.lat();
      const geofenceLong = this.map.geofences[0].circle.getCenter()?.lng();
      this.currentLocationFilter = `Address: ${
        this.formGroup.get('address').value.description
      }, Radius: ${geofenceRadiusInMiles} Miles`;

      this.pilotForSourcingGridExtraFilters.location = `${geofenceLat}, ${geofenceLong}`;
      this.pilotForSourcingGridExtraFilters.radius = geofenceRadiusInMiles;

      this.matExpansionPanelElement.close();

      this.ifBulkActionWasExecutedUncheckMissions();
      this.callApiWithFilters();
    } else {
      this.toaster.error('You need to add 1 geofence!');
    }
  }

  updateMap(event: MatAutocompleteSelectedEvent) {
    const place = event.option.value;
    this._googleMapService.getGeocodeData(place.place_id).subscribe({
      next: response => {
        const geocodeLocation = response.results.length > 0 ? response.results[0] : null;
        if (geocodeLocation) {
          try {
            const latLng = new google.maps.LatLng(geocodeLocation?.geometry?.location);

            const geofenceCreated = this.map.addGeofence(latLng, '', {});

            if (geofenceCreated) {
              this.map.map.panTo(latLng);
              this.map.map.googleMap.setZoom(15);

              this.toaster.info(`Service area for "${place.description}" added.`);
              this.formGroup.get('address').disable();
            }

            this.options = [];
          } catch (error) {
            console.log(error);
          }
        }
      },
      error: error => {
        console.error(`Unable to get geocode data:\n${error}`);
      },
    });
  }

  private initiateAutocomplete() {
    this.formGroup.controls.address.valueChanges.subscribe(input => {
      if (input.length) {
        this._googleMapService.getPlaces(input).subscribe(places => {
          this.options = places.predictions;
        });
      }
    });
  }

  formatOption(option: any): string {
    return option.description || option;
  }

  sendSms(pilot: PilotSourcingDto) {
    this.pilotsForSMS = [];

    const _return = this.identityUserService.get(pilot.userId).subscribe(res => {
      if (res.extraProperties) {
        if (res.extraProperties.enableSMSReception) {
          this.pilotsForSMS.push(pilot);
          this.showForm();
        } else {
          this.toaster.error(
            this.localizationService
              .instant('IdentityService::SMSNotificationValidationMessage')
              .replace('Token', pilot.name),
          );
        }
      } else {
        this.toaster.error(
          this.localizationService
            .instant('IdentityService::SMSNotificationValidationMessage')
            .replace('Token', pilot.name),
        );
      }
    });
  }

  showForm() {
    this.buildNotificationForm();
    this.isModalOpen = true;
  }

  hideForm() {
    this.isModalOpen = false;
    this.notificationForm.reset();
    this.pilotsForSMS = [];
  }

  buildNotificationForm() {
    this.notificationForm = this._fb.group({
      phoneNumbers: [[], []],
      roles: [[], []],
      users: [[], []],
      selectedUser: [[], []],
      message: [null, [Validators.required, Validators.maxLength(320)]],
      description: ['SMS from Pilot Finder', []],
    });
  }

  submitNotificationForm() {
    if (this.notificationForm.invalid) return;

    let formValue = this.notificationForm.value;

    formValue.users = this.pilotsForSMS.map(x => x.userId);

    const _phoneNumbers = this.pilotsForSMS.map(x => x.phone);

    if (_phoneNumbers != null) {
      formValue.phoneNumbers = _phoneNumbers;
    }

    const request = this.notificationService.createMultipleOptions(formValue);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm()),
      )
      .subscribe();
  }

  async openAssignBadgeModal(row: PilotSourcingDto) {
    this.loadingService.showOverlay();
    try {
      const badgePilotData = await this.fetchPilotDetails(row.id);
      this.loadingService.hideOverlay();

      const dialogRef = this.dialog.open(AssignBadgeModalComponent, {
        disableClose: true,
        width: '900px',
        panelClass: 'custom-modalbox',
        data: badgePilotData,
      });

      dialogRef.afterClosed().subscribe({
        next: (selectedBadges: BadgeSelectionDto[]) => {
          if (selectedBadges && selectedBadges.length > 0) {
            this.pilotBadgeService.assignMultipleBadges(selectedBadges).subscribe({
              next: (assignedBadges: PilotBadgeDto[]) => {
                this.callApiWithFilters();
                const pilotName = selectedBadges[0].pilotName;

                this.toaster.success(
                  `Badge assignments for ${pilotName} have been successfully updated.`,
                );
              },
              error: error => {
                console.error('Error assigning badges:', error);
                this.toaster.error('An error occurred while assigning the badges to the pilot.');
              },
            });
          }
        },
        error: error => console.error('Error in dialog:', error),
      });
    } catch (error) {
      this.loadingService.hideOverlay();
      this.toaster.error('An error occurred while fetching pilot details. Please try again.');
    }
  }

  async openRevokeBadgeModal(row: PilotSourcingDto) {
    this.loadingService.showOverlay();
    try {
      const badgePilotData = await this.fetchPilotDetails(row.id);
      this.loadingService.hideOverlay();

      const dialogRef = this.dialog.open(RevokeBadgeModalComponent, {
        disableClose: true,
        width: '900px',
        panelClass: 'custom-modalbox',
        data: badgePilotData,
      });

      dialogRef.afterClosed().subscribe({
        next: (revokedBadgeIds: string[]) => {
          if (revokedBadgeIds && revokedBadgeIds.length > 0) {
            this.pilotBadgeService.revokeBadges(row.id, revokedBadgeIds).subscribe({
              next: () => {
                this.callApiWithFilters();
                this.toaster.success(
                  `Successfully revoked ${revokedBadgeIds.length} badge(s) from pilot ${row.name}.`,
                );
              },
              error: error => {
                console.error('Error revoking badges:', error);
                this.toaster.error('An error occurred while revoking badges. Please try again.');
              },
            });
          }
        },
        error: error => console.error('Error in dialog:', error),
      });
    } catch (error) {
      this.loadingService.hideOverlay();
      this.toaster.error('An error occurred while fetching pilot details. Please try again.');
    }
  }

  private async fetchPilotDetails(pilotId: string): Promise<BadgePilotData> {
    try {
      const pilotDetails = await firstValueFrom(this.pilotService.get(pilotId));

      const experienceLevel = await this.fetchExperienceLevel(
        pilotDetails.pilotExperienceLevelId,
        pilotId,
      );
      const availability = await this.fetchAvailability(pilotDetails.availabilityId, pilotId);

      return {
        pilotId: pilotId,
        pilotName: `${pilotDetails.firstName} ${pilotDetails.lastName}`,
        pilotExperience: experienceLevel,
        pilotStatus: pilotDetails.infoChecked ? 'Active' : 'Inactive',
        availability: availability,
      };
    } catch (error) {
      throw new Error('Failed to fetch pilot details');
    }
  }

  private async fetchExperienceLevel(experienceLevelId: string, pilotId: string): Promise<string> {
    try {
      const expLevel = await firstValueFrom(
        this.pilotExperienceLevelService.get(experienceLevelId),
      );
      return expLevel.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }

  private async fetchAvailability(availabilityId: string | null, pilotId: string): Promise<string> {
    if (!availabilityId) {
      return '-';
    }

    try {
      const availabilityData = await firstValueFrom(this.availabilityService.get(availabilityId));
      return availabilityData.description;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return '-';
      } else {
        throw error;
      }
    }
  }

  private updateAvailabilitiesOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'availabilityId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.description }));
    }
  }

  private updateSensorTypesOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'sensorsType');
    if (condition) {
      condition.options = data.map(item => ({
        id: item.description,
        description: item.description,
      }));
    }
  }

  private updateBadgeOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'pilotBadgesId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private updateContactOptions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'preferredContactStyle');
    if (condition) {
      condition.options = [
        { id: 'Email', description: 'Email' },
        { id: 'Text', description: 'Text' },
        { id: 'Phone Call', description: 'Phone Call' },
        { id: 'In-app Notifications', description: 'In-app Notifications' },
        { id: 'No Preference', description: 'No Preference' },
      ];
    }
  }

  private getAvailabilityValues(): void {
    const query = {} as ABP.PageQueryParams;
    const availabilityFilter = { state: enumState.Enabled } as GetAvailabilitiesInput;

    this.availabilityService
      .getList({
        ...query,
        ...availabilityFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.updateAvailabilitiesOptions(res.items);
        for (let dep of res.items) {
          this.availabilitiesDict[dep.id] = dep.description;
        }
      });
  }

  private getSensorsTypeValues(): void {
    const query = {} as ABP.PageQueryParams;
    const sensorTypesFilter = { state: enumState.Enabled } as GetSensorTypesInput;

    this.sensorTypesService
      .getList({
        ...query,
        ...sensorTypesFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.updateSensorTypesOptions(res.items);
        for (let dep of res.items) {
          this.sensorTypesDict[dep.id] = dep.description;
        }
      });
  }

  getBadgeText(row: PilotSourcingDto): string {
    let badgeText = row.lastBadgeDescription || '';

    if (row.lastBadgeProgressionLevel !== undefined && row.lastBadgeProgressionLevel !== null) {
      const adjustedLevel = row.lastBadgeProgressionLevel + 1;
      badgeText = `(${adjustedLevel}) ${badgeText} - ${
        ProgressionLevel[row.lastBadgeProgressionLevel]
      }`;
    }

    return badgeText;
  }
}
