<div id="chat_wrapper" [class.dark-theme]="themeService.isDark()">
  <h2>Chat</h2>
  <div class="container-fluid">
    <div class="row">
      <!-- Users box-->
      <div class="col-12 col-md-5 col-lg-3 d-flex align-items-stretch">
        <abp-chat-contacts
          class="d-flex w-100"
          (selected)="onSelectContact($event)"
          [startConversation]="clickedStartMessage"
        ></abp-chat-contacts>
      </div>
      <!-- /Users box-->

      <!-- Chat Box-->

      <div class="col-12 col-md-7 col-lg-9 d-flex align-items-stretch">
        <div class="chat-border card w-100">
          <div *ngIf="selectedContact.userId" class="card-body p-0">
            <abp-chat-message-title [selectedContact]="selectedContact"></abp-chat-message-title>
            <ng-scrollbar visibility="hover">
              <div #chatBox id="chat-conversation-wrapper" class="chat-box mb-2">
                <div class="chat-box-container" id="chat-conversation">
                  <ng-container
                    *ngFor="
                      let message of selectedContactMessages;
                      let i = index;
                      trackBy: trackByMessageDate
                    "
                  >
                    <div
                      *ngIf="selectedContactMessages.length - unreadMessageCount === i"
                      class="row justify-content-center unread-message-count-badge-wrapper"
                    >
                      <h3>
                        <span class="badge badge-info">
                          <ng-container *ngIf="unreadMessageCount === 1; else moreThanOne">
                            {{ 'Chat::YouHaveAnUnreadMessage' | abpLocalization }}
                          </ng-container>

                          <ng-template #moreThanOne>
                            {{
                              'Chat::YouHave{0}UnreadMessages'
                                | abpLocalization: '' + unreadMessageCount
                            }}
                          </ng-template>
                        </span>
                      </h3>
                    </div>
                    <div
                      *ngIf="{ isSender: message.side === chatMessageSide.Sender } as data"
                      class="media w-75 mw-65 w-lp-auto mb-2"
                      [class.ms-auto]="data.isSender"
                    >
                      <div
                        class="media-body position-relative"
                        *ngIf="!validateImage(message.message)"
                      >
                        <div
                          class="media-container py-2 px-2 py-lg-2 px-lg-3 mb-1 {{
                            data.isSender ? 'media-container__sender' : 'media-container__reciever'
                          }}"
                        >
                          <p
                            class="message-text text-small mb-0 lh-base {{
                              data.isSender ? 'text-white' : 'text-dark'
                            }}"
                            [innerHTML]="message.message"
                          ></p>
                        </div>
                        <p class="message-date m-0 {{ data.isSender ? 'right' : 'left' }}">
                          {{
                            getDateInLocalTime(message.messageDate)
                              | date: getDateFormat(message.messageDate)
                          }}
                        </p>
                      </div>
                      <div
                        class="media-body position-relative"
                        *ngIf="validateImage(message.message)"
                      >
                        <ng-container *ngFor="let message of separateMessages(message.message)">
                          <div
                            class="media-container py-2 px-2 py-lg-2 px-lg-3 mb-1 {{
                              data.isSender ? 'chat-img-right' : 'chat-img-left'
                            }}"
                            *ngIf="validateImage(message)"
                          >
                            <img
                              [id]="getImageProp(message, 'id')"
                              [src]="getImageProp(message, 'src')"
                              [alt]="getImageProp(message, 'name')"
                              class="chat-img-conversations"
                              (click)="viewErrorzoomImage(message)"
                            />
                          </div>
                          <div
                            class="media-container py-2 px-2 py-lg-2 px-lg-3 mb-1 {{
                              data.isSender
                                ? 'media-container__sender'
                                : 'media-container__reciever'
                            }}"
                            *ngIf="!validateImage(message)"
                          >
                            <p
                              class="message-text text-small mb-0 lh-base {{
                                data.isSender ? 'text-white' : 'text-dark'
                              }}"
                              [innerHTML]="message"
                            ></p>
                          </div>
                        </ng-container>
                        <p class="message-date m-0 {{ data.isSender ? 'right' : 'left' }}">
                          {{
                            getDateInLocalTime(message.messageDate)
                              | date: getDateFormat(message.messageDate)
                          }}
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-scrollbar>

            <!-- Typing area -->
            <div *ngIf="selectedContact?.userId" id="send-message-form" class="m-0">
              <div class="pe-2 attribute flex-column">
                <div class="file-spinner" *ngIf="loadingFile"></div>

                <div *ngIf="stringValue && !loadingFile">
                  <mat-chip-list>
                    <mat-chip
                      (removed)="onFileRemoved()"
                      class="me-2 mt-2"
                      [title]="this.fileValue"
                    >
                      <span class="width-50" (click)="viewErrorzoomImage(imageMessage)">{{
                        fileValue
                      }}</span>
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-between ps-2 pe-2 pt-3">
                <div>
                  <app-file-upload
                    *ngIf="!stringValue && !loadingFile"
                    [includeBorder]="false"
                    (filesSelected)="onFileSelected($event)"
                    (fileRemoved)="onFileRemoved(field)"
                    [allowMultiple]="false"
                    [showFilesAttached]="false"
                    [seeIconButton]="true"
                    [allowedExtensions]="[
                      '.png',
                      '.jpg',
                      '.jpeg',
                      '.bmp',
                      '.gif',
                      '.tiff',
                      '.tif',
                      '.ico',
                    ]"
                  >
                  </app-file-upload>
                </div>
                <div class="form-control rounded bg-white control-include-div me-2">
                  <textarea
                    id="chat-message-box"
                    name="message"
                    type="text"
                    [placeholder]="'Chat::TypeMessage' | abpLocalization"
                    class="form-control rounded bg-white"
                    [(ngModel)]="message"
                    (keydown.enter)="sendWithEnter($event)"
                  ></textarea>
                  <div *ngIf="validateImage(imageMessage)">
                    <img
                      [id]="getImageProp(imageMessage, 'id')"
                      [src]="getImageProp(imageMessage, 'src')"
                      [alt]="getImageProp(imageMessage, 'name')"
                      class="chat-img-conversations"
                      (click)="viewErrorzoomImage(imageMessage)"
                    />
                  </div>
                  <textarea
                    id="chat-message-box"
                    name="message1"
                    type="text"
                    class="form-control rounded bg-white control-include-div"
                    [(ngModel)]="message1"
                    (keydown.enter)="sendWithEnter($event)"
                    *ngIf="validateImage(imageMessage)"
                  ></textarea>
                </div>
                <button
                  mat-icon-button
                  [disabled]="!message?.trim() && !imageMessage"
                  (click)="send()"
                  id="send-message-button"
                  type="button"
                >
                  <span class="material-symbols-outlined" *ngIf="!loading; else loadingIcon">
                    send
                  </span>

                  <ng-template #loadingIcon>
                    <span class="material-symbols-outlined"> hourglass_empty </span>
                  </ng-template>
                </button>
              </div>
              <div class="d-flex align-items-center justify-content-end px-3 py-2">
                <div class="d-flex">
                  <div class="d-flex align-items-center form-check mt-2">
                    <input
                      type="checkbox"
                      class="form-check-input border shadow-sm me-1"
                      id="send-on-enter"
                      [(ngModel)]="sendOnEnter"
                    />
                    <label class="form-check-label" for="send-on-enter">{{
                      'Chat::SendOnEnter' | abpLocalization
                    }}</label>
                  </div>
                  <div
                    class="d-flex align-items-center form-check mt-2 ms-2"
                    *abpPermission="'General.Common.Actions.SMSSending'"
                  >
                    <input
                      type="checkbox"
                      class="form-check-input border shadow-sm me-1"
                      id="send-on-enter"
                      [(ngModel)]="sendAsSMS"
                    />
                    <label class="form-check-label" for="send-on-enter">{{
                      'Chat::SendAsSMS' | abpLocalization
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!selectedContact.userId" class="card-body pt-5 pb-5 text-center">
            <i class="fa fa-commenting-o fs-2" aria-hidden="true"></i>
            <p class="pb-3">{{ 'Chat::NoMessageYet' | abpLocalization }}</p>
            <p>{{ 'Chat::NoMessageYetMessage' | abpLocalization }}</p>
            <button (click)="startConversation()" type="button" class="btn btn-primary">
              {{ 'Chat::StartConversation' | abpLocalization }}
            </button>
          </div>
        </div>
      </div>
      <!-- /Chat Box-->
    </div>
  </div>
</div>
