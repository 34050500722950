<abp-page [title]="'missionsService::Expenses' | abpLocalization">
  <abp-page-toolbar-container class="col">
    <div>
      <button
        *ngIf="
          permissionService.getGrantedPolicy$('missionsService.MissionFinancialExpenses.Reconcile')
            | async
        "
        mat-flat-button
        type="button"
        class="common-button"
        type="button"
        (click)="reconcileMission()"
        [disabled]="loadingReconcile"
      >
        <div class="button-reconcile">
          <div *ngIf="loadingReconcile" class="file-spinner"></div>
          <span>Reconcile Mission</span>
        </div>
      </button>
    </div>
    <div class="text-lg-end mb-2">
      <button class="btn btn-primary btn-sm" type="button">
        <lib-management-actions
          class="pointer flex"
          gridName="Expenses"
          [rowData]="{
            title: 'New Expense',
            defDescription: '',
            missionId: missionId,
            internalExpense: 'false',
            captureId: captureId,
            paymentStatusId: defaultPaymentStatusId,
          }"
          uniqueAction="add_expenses"
          [alwaysExecuteParentEvent]="true"
          (parentEvent)="reloadPilotSourcing()"
          [additionalData]="pilots"
        >
          <span class="d-flex text-white">
            <mat-icon>add</mat-icon>
            Add New
          </span>
        </lib-management-actions>
      </button>
    </div>
  </abp-page-toolbar-container>

  <div class="card">
    <div class="card-body">
      <ngx-datatable
        class="flg-grid-table"
        [headerHeight]="45"
        [footerHeight]="55"
        [rowHeight]="45"
        [rows]="data.items"
        [count]="data.totalCount"
        [list]="list"
        default
        [externalPaging]="true"
        [offset]="pageNumber"
        [limit]="clientMaxResultCount"
        #dataTable
      >
        <ngx-datatable-column
          name="{{ 'missionsService::Expense Type' | abpLocalization }}"
          prop="expenseTypeId"
          *ngIf="showedColumns.expenseType"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ expenseTypes[row.expenseTypeId] }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Description' | abpLocalization }}"
          prop="description"
          *ngIf="showedColumns.description"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.description }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Vendor' | abpLocalization }}"
          prop="vendor"
          *ngIf="showedColumns.vendor"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.vendorId ? row.vendorName : 'Internal' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::Units' | abpLocalization }}"
          prop="units"
          *ngIf="showedColumns.units"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.units }}
          </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column
          name="{{ 'missionsService::UOM' | abpLocalization }}"
          prop="uomId"
          *ngIf="showedColumns.uom"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ uomTypes[row.uomId] }}
          </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column
          name="{{ 'missionsService::ItemCost' | abpLocalization }}"
          prop="itemCost"
          *ngIf="showedColumns.itemCost"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            ${{ row.itemCost | number: '1.2-2' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::TotalItemCost' | abpLocalization }}"
          prop="totalItemCost"
          *ngIf="showedColumns.totalItemCost"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="total-gray">${{ row.totalItemCost | number: '1.2-2' }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'missionsService::ExpenseDate' | abpLocalization }}"
          prop="Expense Date"
          *ngIf="showedColumns.expenseDate"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.fixedExpenseDate || (row.expenseDate + 'Z' | date: 'MM/dd/yyyy') }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'CoreService::PaymentStatus' | abpLocalization }}"
          prop="PaymentStatusDescription"
          [sortable]="false"
          *ngIf="showedColumns.paymentStatus"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.paymentStatusDescription || '--' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          name="{{ 'CoreService::Reconciliation' | abpLocalization }}"
          prop="reconciliationStatusDescription"
          [sortable]="false"
          *ngIf="showedColumns.reconciliationStatusDescription"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.reconciliationStatusDescription ?? 'Not Matched' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          [name]="'AbpUi::Actions' | abpLocalization"
          [maxWidth]="150"
          [width]="150"
          [sortable]="false"
        >
          <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
            <div class="flg-grid-table-actions">
              <div ngbDropdown container="body" class="d-inline-block">
                <!-- <button
                class="btn btn-primary btn-sm dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                ngbDropdownToggle
              >
                <i class="fa fa-cog me-1" aria-hidden="true"></i>
                {{ 'AbpUi::Actions' | abpLocalization }}
              </button>

              <div ngbDropdownMenu>
                <button
                  ngbDropdownItem
                  *abpPermission="'missionsService.MissionFinancialExpenses.Edit && General.Common.Edit'"
                  (click)="update(row)"
                >
                  {{ 'AbpUi::Edit' | abpLocalization }}
                </button>

                <button
                  ngbDropdownItem
                  *abpPermission="'missionsService.MissionFinancialExpenses.Delete && General.Common.Delete'"
                  (click)="delete(row)"
                >
                  {{ 'AbpUi::Delete' | abpLocalization }}
                </button>
              </div> -->

                <lib-management-actions
                  class="pointer flex"
                  gridName="Expenses"
                  [rowData]="{
                    id: row.id,
                    title: 'Edit Expense',
                    defExpenseTypeId: row.expenseTypeId ?? '',
                    defDescription: row.description ?? '',
                    defUnits: row.units ?? '',
                    defUomId: row.uomId ?? '',
                    defItemCost: row.itemCost,
                    defTotalItemCost: row.totalItemCost,
                    defExpenseDate: row.fixedExpenseDate || (row.expenseLocalDate ?? ''),
                    missionId: missionId,
                    parentId: row.id,
                    captureId: row.captureId,
                    internalExpense: row.vendorId ? 'false' : 'true',
                    vendorId: row.vendorId,
                    paymentStatusId: row.paymentStatusId ?? defaultPaymentStatusId,
                  }"
                  uniqueAction="add_expenses"
                  [alwaysExecuteParentEvent]="true"
                  (parentEvent)="reloadPilotSourcing()"
                  [additionalData]="pilots"
                >
                  <mat-icon class="material-symbols-rounded pointer">edit</mat-icon>
                </lib-management-actions>
              </div>
              <a
                class="ng-star-inserted"
                *abpPermission="
                  'missionsService.MissionFinancialExpenses.Delete && General.Common.Delete'
                "
                matTooltipPosition="left"
                role="button"
                (click)="delete(row)"
                matTooltip="{{ 'AbpUi::Delete' | abpLocalization }}"
              >
                <span class="material-symbols-rounded mr-8px">delete</span>
              </a>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-curPage="curPage"
          >
            <app-table-paginator
              [(maxResultCount)]="list.maxResultCount"
              [(clientMaxResultCount)]="clientMaxResultCount"
              [rowCount]="rowCount"
              [pageSize]="pageSize"
              [(pageNumber)]="pageNumber"
              [curPage]="curPage"
              [dataTable]="dataTable"
            >
            </app-table-paginator>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</abp-page>

<app-responsive-grid
  class="d-none"
  gridName="Expenses"
  (action)="setAction($event)"
></app-responsive-grid>
