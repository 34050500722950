import { DragDropModule } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';

import { MissionsComponent } from './components/missions.component';
import { MissionsRoutingModule } from './missions-routing.module';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from 'projects/flyguys/src/environments/environment';
import { ColumnsModule } from 'projects/flyguys/src/app/components/columns/columns.module';
import { TablePageModule } from 'projects/flyguys/src/app/components/table-page/table-page.module';

import { SharedModule } from 'projects/flyguys/src/app/shared/shared.module';
import { CopyMissionModalComponent } from './components/copy-mission-modal/copy-mission-modal.component';
import { PilotSourcingModule } from 'projects/flyguys/src/app/pilot-sourcing/pilot-sourcing.module';
import { CustomViewModalComponent } from './components/custom-view-modal/custom-view-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CalculationDueDatesComponent } from './components/calculation-due-dates/calculation-due-dates.component';
import { ShowSlaMilestonesComponent } from './components/show-sla-milestones/show-sla-milestones.component';
import { AssignPilotModalComponent } from './components/assign-pilot-modal/assign-pilot-modal.component';
import { TransferFilesStatusModalComponent } from './components/transfer-files/transfer-files-status-modal.component';
import { CustomViewNotificationModalComponent } from './components/custom-view-notification-modal-component/custom-view-notification-modal-component.component';

@NgModule({
  declarations: [
    MissionsComponent,
    CopyMissionModalComponent,
    CustomViewModalComponent,
    CalculationDueDatesComponent,
    AssignPilotModalComponent,
    TransferFilesStatusModalComponent,
    CustomViewNotificationModalComponent,
  ],
  imports: [
    MissionsRoutingModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    PageModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ColumnsModule,
    TablePageModule,
    ReactiveFormsModule,
    SharedModule,
    PilotSourcingModule,
    DragDropModule,
    MatSlideToggleModule,
  ],
  exports: [MissionsComponent],
})
export class MissionsModule {}
