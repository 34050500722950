<div *ngIf="loading">
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="true"
    [zIndex]="9999"
  >
    <p style="color: #bc5a2e">{{ 'missionsService::LoadingSourcePilots' | abpLocalization }}</p>
  </ngx-spinner>
</div>

<div *ngIf="!loading">
  <h5 mat-dialog-title class="modalTitle">Pilot Information</h5>

  <mat-dialog-content>
    <div class="modalBody" modalBody>
      <div class="row justify-content-center">
        <p><b>Name:</b> {{ data.pilotName || 'N/A' }}</p>
        <p><b>Latitude:</b> {{ data.coordinateLat || 'N/A' }}</p>
        <p><b>Longitude:</b> {{ data.coordinateLng || 'N/A' }}</p>
        <p><b>Address:</b> {{ data.pilotAddress || 'N/A' }}</p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flg-modal flg-modal__actions" align="end">
    <div class="d-flex justify-content-end mt-3" modalActions>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        cdkFocusInitial
        matDialogClose
      >
        Ok
      </button>
    </div>
  </mat-dialog-actions>
</div>
