import type { FullAuditedEntityDto, PagedAndSortedResultRequestDto } from '@abp/ng.core';
import { enumState } from '../shared/enum-state.enum';
import { enumCaptureMustBe } from '../shared/enum-captureMustBe.enum';
import { recurrenceEnum } from '../shared/recurrency.enum';
import { CaptureMustBeEnum } from '../shared/capture-mustbe.enum';
import { ActionOrdered } from '../../../../../../flyguys/src/app/pilot-sourcing/models/action-ordered-dto';
import { ActionOrderConfiguredDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { MissionOrderDetailsDto, OrderResumeDto } from '../relationals';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { enumBulkUpload } from './enum-bulkupload.enum';

export interface AddressesCreateDto {
  streetAddress: string;
  countryId: string;
  countryName: string;
  stateId: string;
  stateName: string;
  city: string;
  zipCode: string;
  state: enumState;
}

export interface AddressesDto extends FullAuditedEntityDto<string> {
  streetAddress: string;
  countryId: string;
  stateId: string;
  city: string;
  zipCode: string;
  state: enumState;
}

export interface AddressesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface AddressesUpdateDto {
  streetAddress: string;
  city: string;
  zipCode: string;
  country: string;
  stateProvince: string;
  state: enumState;
  countryId?: string;
  countryName?: string;
  stateId?: string;
  stateName?: string;
}

export interface AttributesCreateDto {
  decription: string;
  typeId: string;
  state: enumState;
  listValues?: string;
  defaultValue?: string;
  isRequired?: boolean;
  maxTextLength?: number;
  displayName: string;
  typeDescription?: string;
  typeCode?: string;
}

export interface AttributesDto extends FullAuditedEntityDto<string> {
  description: string;
  typeId: string;
  state: enumState;
  listValues?: string;
  defaultValue?: string;
  isRequired?: boolean;
  maxTextLength?: number;
  displayName?: string;
  typeDescription?: string;
  typeCode?: string;
}

export interface AttributesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  decription?: string;
  typeId?: string;
  state?: enumState;
  listValues?: string;
  defaultValue?: string;
  isRequired?: boolean;
  maxTextLength?: number;
  displayName?: string;
  typeDescription?: string;
  typeCode?: string;
}

export interface AttributesUpdateDto {
  decription: string;
  typeId: string;
  state: enumState;
  listValues?: string;
  defaultValue?: string;
  isRequired?: boolean;
  maxTextLength?: number;
  displayName: string;
  typeDescription?: string;
  typeCode?: string;
}

export interface DeliverableAttributesCreateDto {
  deliverableId: string;
  attributeId: string;
}

export interface DeliverableAttributesDto extends FullAuditedEntityDto<string> {
  deliverableId: string;
  attributeId: string;
}

export interface DeliverableAttributesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  deliverableId?: string;
  attributeId?: string;
}

export interface DeliverableAttributesUpdateDto {
  deliverableId: string;
  attributeId: string;
}

export interface ProductsCreateDto {
  alias: string;
  description?: string;
  industries: string;
  percentageAdjustment: number;
  state: enumState;
  processingWorkflow: boolean;
  notes: string;
  code?: string;
}

export interface ProductsDto extends FullAuditedEntityDto<string> {
  alias: string;
  description: string;
  industries: string;
  percentageAdjustment: number;
  state: enumState;
  price: number;
  processingWorkflow: boolean;
  notes: string;
  code?: string;
}

export interface ProductsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  alias?: string;
  description?: string;
  industryId?: string;
  percentageAdjustment?: number;
  state?: enumState;
  code?: string;
}

export interface ProductsUpdateDto {
  alias: string;
  description?: string;
  industries: string;
  percentageAdjustment: number;
  state: enumState;
  processingWorkflow: boolean;
  notes: string;
  code?: string;
}

export interface BadgesCreateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  description?: string;
  image?: string;
  fileId?: string;
  state: enumState;
  categoryId?: string;
  hasProgression: boolean;
  isActive: boolean;
}

export interface BadgesDto extends FullAuditedEntityDto<string> {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  description?: string;
  image?: string;
  fileId?: string;
  state: enumState;
  isActive: boolean;
  hasProgression: boolean;
  categoryId?: string;
}

export interface BadgesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface BadgesUpdateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  description?: string;
  image?: string;
  fileId?: string;
  state: enumState;
  categoryId?: string;
  hasProgression: boolean;
  isActive: boolean;
}

export interface GridViewsCreateDto {
  name: string;
  private: boolean;
  viewDefinition: string;
  userId: string;
  viewType?: number;
}

export interface GridViewsUpdateDto {
  id: string;
  name: string;
  private: boolean;
  userId: string;
  viewType?: number;
}

export interface GridViewsDto extends FullAuditedEntityDto<string> {
  name: string;
  viewDefinition: string;
  state: enumState;
  displayStar?: boolean;
  private: boolean;
  userId: string;
  viewType?: number;
}

export interface GridViewsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface GridViewAssignationDto {
  userId: string;
  gridViewId: string;
}

export interface CapturesCreateDto {
  date: string;
  time?: string;
  flexibleCaptureTime: boolean;
  captureDateMust: CaptureMustBeEnum;
  captureTypeId?: string;
  missionId?: string;
  captureTypeDescription?: string;
  missionDescription?: string;
  state: enumState;
  fixedDate: string;
  fixedTime?: string;
}

export interface CapturesDto extends FullAuditedEntityDto<string> {
  date: string;
  time: string;
  flexibleCaptureTime: string;
  captureDateMust: enumCaptureMustBe;
  captureTypeId?: string;
  missionId?: string;
  captureTypeDescription?: string;
  missionDescription?: string;
  state: enumState;
  potentialPilotPay?: number;
  pilotPay: number;
  pilotPayDate: Date;
  scopeOfWork?: string;
  statusCapture: string;
  captureNumber?: number;
}

export interface CapturesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CapturesUpdateDto {
  date: string;
  time: string;
  flexibleCaptureTime: string;
  captureDateMust: enumCaptureMustBe;
  captureTypeId: string;
  missionId?: string;
  captureTypeDescription?: string;
  missionDescription?: string;
  state: enumState;
}

export interface ContactsCreateDto {
  contactId?: string;
  contactTypeId?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  levelCommunicationId?: string;
  shareData: boolean;
  contactMethodId?: string;
  state: enumState;
  contactTypeDescription?: string;
  levelCommunicationDescription?: string;
  contactMethodDescription?: string;
  userId?: string;
  descriptionUser?: string;
}

export interface ContactsDto extends FullAuditedEntityDto<string> {
  contactTypeId?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  levelCommunicationId?: string;
  contactMethodId?: string;
  contactMethod: string;
  state: enumState;
  contactTypeDescription?: string;
  levelCommunicationDescription?: string;
  contactMethodDescription?: string;
  userId?: string;
  descriptionUser?: string;
}

export interface ContactsTmpDto {
  id: number;
  contactFrom: string;
  name: string;
  phone?: string;
  email?: string;
  sharedData?: boolean;
}

export interface ContactsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ContactsUpdateDto {
  contactTypeId?: string;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  levelCommunicationId?: string;
  contactMethodId?: string;
  state: enumState;
  contactTypeDescription?: string;
  levelCommunicationDescription?: string;
  contactMethodDescription?: string;
  userId?: string;
  descriptionUser?: string;
}

export interface ContactWithNavigationPropertiesDto {
  contacts: ContactsDto;
  user: IdentityUserDto;
}

export interface DeliverableAspectCreateDto {
  deliverableDueDate: string;
  notes: string;
  instructions: string;
  deliveryAllSameTime: Boolean;
  state: enumState;
  fixedDeliverableDueDate: string;
}

export interface CredentialsCreateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  image?: string;
  fileId?: string;
  state: enumState;
}

export interface CredentialsDto extends FullAuditedEntityDto<string> {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  state: enumState;
  image?: string;
  fileId?: string;
  description?: string;
}

export interface CredentialsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface CredentialsUpdateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  image?: string;
  fileId?: string;
  state: enumState;
}

export interface DeliverablesCreateDto {
  name: string;
  attributes?: string[];
  fileTypes?: string[];
  description?: string;
  cost?: number;
  state: enumState;
  templateId?: string;
  categoryId?: string;
  categoryDescription?: string;
  premium?: boolean;
  code?: string;
}

export interface DeliverablesDto extends FullAuditedEntityDto<string> {
  name: string;
  attributes?: string[];
  fileTypes?: string[];
  description?: string;
  cost?: number;
  state: enumState;
  templateId?: string;
  categoryId?: string;
  categoryDescription?: string;
  premium?: boolean;
  code?: string;
}

export interface DeliverablesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface DeliverablesUpdateDto {
  name: string;
  attributes?: string[];
  fileTypes?: string[];
  description?: string;
  cost?: number;
  state: enumState;
  templateId?: string;
  categoryId?: string;
  categoryDescription?: string;
  premium?: boolean;
  code?: string;
}

export interface EquipmentsCreateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturerId?: string;
  specs?: string;
  image?: string;
  fileId?: string;
  state: enumState;
  sensorTypeId?: string;
  sensorTypeDescription?: string;
  statusEquipmentId?: string;
  statusEquipmentDescription?: string;
  rtkCompatible?: boolean;
}

export interface EquipmentsDto extends FullAuditedEntityDto<string> {
  name: string;
  model?: string;
  brand?: string;
  manufacturerId?: string;
  specs?: string;
  equipmentTypeId?: string;
  state: enumState;
  image?: string;
  fileId?: string;
  description?: string;
  sensorTypes: string[];
  statusEquipmentId?: string;
  statusEquipmentDescription?: string;
  rtkCompatible?: boolean;
}

export interface EquipmentsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
  sensorTypeId?: string;
  statusEquipmentId?: string;
  statusEquipmentDescription?: string;
  rtkCompatible?: boolean;
}

export interface EquipmentsUpdateDto {
  name: string;
  model?: string;
  brand?: string;
  manufacturerId?: string;
  specs?: string;
  image?: string;
  fileId?: string;
  state: enumState;
  sensorTypes: string[];
  statusEquipmentId?: string;
  statusEquipmentDescription?: string;
  rtkCompatible?: boolean;
}

export interface FlightDeliverablesCreateDto {
  fileId: string;
  poductId: string;
  state: enumState;
}

export interface FlightDeliverablesDto extends FullAuditedEntityDto<string> {
  fileId: string;
  poductId: string;
  productDescription: string;
  state: enumState;
}

export interface FlightDeliverablesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface FlightDeliverablesUpdateDto {
  fileId: string;
  poductId: string;
  state: enumState;
}

export interface GetAddressInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  streetAddress?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  stateProvince?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetAttributesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  typeId?: string;
  state?: enumState;
  isPaginated?: boolean;
  listValues?: string;
  defaultValue?: string;
  isRequired?: boolean;
  maxTextLength?: number;
  displayName?: string;
  typeDescription?: string;
}

export interface GetDeliverableAttributesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  deliverableId?: string;
  attributeId?: string;
  isPaginated?: boolean;
}

export interface GetProductsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  alias?: string;
  description?: string;
  industryId?: string;
  state?: enumState;
  isPaginated?: boolean;
  processingWorkflow?: boolean;
  code?: string;
}

export interface GetBadgeInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  state?: enumState;
  fileId?: string;
  isPaginated?: boolean;
}

export interface GetGridViewsInput extends PagedAndSortedResultRequestDto {
  private?: boolean;
  userId: string;
  isAdmin: boolean;
  filterText?: string;
  name?: string;
  state?: enumState;
  isPaginated?: boolean;
  viewType?: number;
}

export interface GetCaptureInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  dateMin?: string;
  dateMax?: string;
  time?: string;
  captureTypeId?: string;
  missionId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetContactInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  filter?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  contactTypeId?: string;
  levelCommunicationId?: string;
  contactMethodId?: string;
  contactTypeDescription?: string;
  levelCommunicationDescription?: string;
  contactMethodDescription?: string;
  state?: enumState;
  userId?: string;
  descriptionUser?: string;
  isPaginated?: boolean;
}

export interface GetCredentialInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  model?: string;
  brand?: string;
  manufacturer?: string;
  specs?: string;
  state?: enumState;
  fileId?: string;
  isPaginated?: boolean;
}

export interface GetDeliverableInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  attributeId?: string;
  fileTypeId?: string;
  description?: string;
  costMin?: number;
  cost?: number;
  costMax?: number;
  state?: enumState;
  premium?: boolean;
  code?: string;
  templateId?: string;
  categoryId?: string;
  isPaginated?: boolean;
}

export interface GetEquipmentInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  model?: string;
  brand?: string;
  manufacturerId?: string;
  specs?: string;
  equipmentTypeId?: string;
  state?: enumState;
  fileId?: string;
  sensorTypeId?: string;
  sensorTypeDescription?: string;
  statusEquipmentId?: string;
  statusEquipmentDescription?: string;
  rtkCompatible?: boolean;
  isPaginated?: boolean;
}

export interface GetFlightDeliverableInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  fileId?: string;
  poductId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetLocationContactInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  locationId?: string;
  contactId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetLocationInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  gpsCoordinates?: string;
  geoFenceRadio?: string;
  siteContact?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetMissionDeliverableInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  byDefault?: boolean;
  actualPriceMin?: number;
  actualPriceMax?: number;
  poductId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetMissionInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  filter?: string;
  name?: string;
  summary?: string;
  customerId?: string;
  startDataMin?: string;
  startDataMax?: string;
  finishDataMin?: string;
  finishDataMax?: string;
  industryId?: string;
  projectId?: string;
  state?: enumState;
  locationId?: string;
  additionalCharges?: string;
  additionalNotes?: string;
  orderId?: string;
  thirdPartyId?: string;
  priorityId?: string;
  PilotSourcing?: boolean;
  isPaginated?: boolean;
  assignedToAnyone?: boolean;
  inAnyStatus?: boolean;
  assignUserId?: string;
}

export interface GetMissionPilotNotificationInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  pilotId?: string;
  missionId?: string;
  notificationId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetMissionPilotRatingInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  pilotId?: string;
  missionId?: string;
  missionQualityQuestionnarieId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetMissionPilotRatingNoteInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  missionPilotRatingId?: string;
  noteId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetMissionQualityQuestionnarieInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  questionId?: string;
  responseId?: string;
  value?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetNoteInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  subject?: string;
  body?: string;
  categoryId?: string;
  departamentId?: string;
  state?: enumState;
  isPaginated?: boolean;
  name?: string;
}

export interface GetOrderDetailInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  orderId?: string;
  locationId?: string;
  productId?: string;
  qtyMin?: number;
  qtyMax?: number;
  deliverableId?: string;
  unitPriceMin?: number;
  unitPriceMax?: number;
  totalMin?: number;
  totalMax?: number;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetOrderInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  portfolioId?: string;
  requestingCustomerId?: string;
  billingCustomerId?: string;
  endCustomerId?: string;
  totalPriceMin?: number;
  totalPriceMax?: number;
  industryId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetPortafolioContactInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  portafolioId?: string;
  contactId?: string;
  state?: enumState;
  projectManager?: boolean;
  isPaginated?: boolean;
}

export interface GetPortafolioInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  customerId?: string;
  billingCustomerId?: string;
  subCustomerId?: string;
  state?: enumState;
  isPaginated?: boolean;
  assignedToAnyone?: boolean;
  assignUserId?: string;
}

export interface GetProjectInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  portafolioId?: string;
  recurence?: recurrenceEnum;
  totalMissions?: number;
  filterOrderRequest?: boolean;
  state?: enumState;
  isPaginated?: boolean;
  assignedToAnyone?: boolean;
  assignUserId?: string;
}

export interface GetQuestionInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  questionTypeId?: string;
  questionDataTypeId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetResponseInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  questionId?: string;
  responseTypeId?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetServiceInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  description?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetTeamOrderInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  roleId?: string;
  order?: number;
  roleDescription?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetStatusMissionOrderInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  statusId?: string;
  statusDescription?: string;
  statusEnum?: string;
  code?: string;
  order?: number;
  state?: enumState;
  isPaginated?: boolean;
}

export interface GetOrderMissionEventsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  description?: string;
  code?: string;
  order?: number;
  state?: enumState;
  isPaginated?: boolean;
}

export interface LocationContactsCreateDto {
  locationId: string;
  contactId: string;
  state: enumState;
}

export interface LocationContactsDto extends FullAuditedEntityDto<string> {
  locationId: string;
  contactId: string;
  state: enumState;
}

export interface SiteContactsDto {
  customerId: string;
  locationContactId: string;
  locationId: string;
  siteName: string;
  siteAddress: string;
  contactId: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
}

export interface LocationContactsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface LocationContactsUpdateDto {
  locationId: string;
  contactId: string;
  state: enumState;
}

export interface LocationsCreateDto {
  gpsCoordinates: string;
  geoFenceRadio: string;
  airSpaceClasificationId: string;
  airspaceClassificationName: string;
  siteName: string;
  state: enumState;
}

export interface LocationsDto extends FullAuditedEntityDto<string> {
  gpsCoordinates: string;
  geoFenceRadio: string;
  siteContact: string;
  state: enumState;
}

export interface LocationWithAddressDto {
  locationId: string;
  customerId: string;
  missionId?: string;
  siteName?: string;
  streetAddress?: string;
  countryId?: string;
  countryName?: string;
  stateId?: string;
  stateName?: string;
  city?: string;
  zipCode?: string;
  gpsCoordinates?: string;
  latitude?: string;
  longitude?: string;
  airSpaceClassificationId?: string;
  airSpaceClassificationDescription?: string;
}

export interface LocationsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface LocationsUpdateDto {
  gpsCoordinates: string;
  geoFenceRadio: string;
  siteContact: string;
  state: enumState;
}

export interface MissionDeliverablesCreateDto {
  byDefault: boolean;
  actualPrice: number;
  poductId: string;
  state: enumState;
}

export interface MissionDeliverablesDto extends FullAuditedEntityDto<string> {
  byDefault: boolean;
  actualPrice: number;
  poductId: string;
  productDescription: string;
  state: enumState;
}

export interface MissionDeliverablesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionDeliverablesUpdateDto {
  byDefault: boolean;
  actualPrice: number;
  poductId: string;
  state: enumState;
}

export interface MissionPilotNotificationsCreateDto {
  pilotId: string;
  missionId: string;
  notificationId: string;
  state: enumState;
}

export interface MissionPilotNotificationsDto extends FullAuditedEntityDto<string> {
  pilotId: string;
  missionId: string;
  descriptionMission: string;
  notificationId: string;
  state: enumState;
}

export interface MissionPilotNotificationsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionPilotNotificationsUpdateDto {
  pilotId: string;
  missionId: string;
  notificationId: string;
  state: enumState;
}

export interface MissionPilotRatingNotesCreateDto {
  missionPilotRatingId: string;
  noteId: string;
  state: enumState;
}

export interface MissionPilotRatingNotesDto extends FullAuditedEntityDto<string> {
  missionPilotRatingId: string;
  noteId: string;
  state: enumState;
}

export interface MissionPilotRatingNotesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionPilotRatingNotesUpdateDto {
  missionPilotRatingId: string;
  noteId: string;
  state: enumState;
}

export interface MissionPilotRatingsCreateDto {
  pilotId: string;
  missionId: string;
  missionQualityQuestionnarieId: string;
  state: enumState;
}

export interface MissionPilotRatingsDto extends FullAuditedEntityDto<string> {
  pilotId: string;
  missionId: string;
  descriptionMission: string;
  missionQualityQuestionnarieId: string;
  state: enumState;
}

export interface MissionPilotRatingsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionPilotRatingsUpdateDto {
  pilotId: string;
  missionId: string;
  missionQualityQuestionnarieId: string;
  state: enumState;
}

export interface MissionQualityQuestionnariesCreateDto {
  description: string;
  questionId: string;
  responseId?: string;
  value?: string;
  state: enumState;
}

export interface MissionQualityQuestionnariesDto extends FullAuditedEntityDto<string> {
  description: string;
  questionId: string;
  responseId?: string;
  value?: string;
  state: enumState;
}

export interface MissionQualityQuestionnariesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionQualityQuestionnariesUpdateDto {
  description: string;
  questionId: string;
  responseId?: string;
  value?: string;
  state: enumState;
}

export interface MissionsCreateDto {
  name: string;
  summary: string;
  customerId?: string;
  customerName?: string;
  startData?: string;
  finishData?: string;
  industryId?: string;
  projectId?: string;
  state: enumState;
  locationId?: string;
  additionalCharges?: string;
  additionalNotes?: string;
  orderId?: string;
  thirdPartyId?: string;
  priorityId?: string;
  priorityDescription: string;
  cost?: number;
  kmlFilePath?: string;
  kmlFileId?: string;
  uploadDataLocationId?: string;
  uploadDataLocation?: string;
  externalLink?: string;
  externalLinkName?: string;
}

export interface PortfolioMissionsCreateDto {
  name: string;
  summary: string;
  startData?: string;
  finishData?: string;
  industryId?: string;
  projectId?: string;
  state: enumState;
  locationId?: string;
  additionalCharges?: string;
  additionalNotes?: string;
  orderId?: string;
  thirdPartyId?: string;
  cost?: number;
  kmlFilePath?: string;
  kmlFileId?: string;
}

export interface CaptureDeliverablesAttributesDto {
  attributeId?: string;
  attributeName?: string;
  attributeValue?: string;
  captureDeliverableId?: string;
  deliverableId?: string;
}

export interface AssignedDto {
  role: string;
  user: string;
  userId: string;
  roleId: string;
}

export interface MissionsDto extends FullAuditedEntityDto<string> {
  selected: boolean;
  actionData: any;
  name: string;
  summary: string;
  customerId: string;
  startData: string;
  finishData: string;
  industryId: string;
  industryDescription: string;
  projectId: string;
  state: enumState;
  locationId: string;
  additionalCharges?: string;
  additionalNotes?: string;
  orderId: string;
  thirdPartyId: string;
  priorityId: string;
  priorityDescription: string;
  missionStatus: number;
  pilotSourcingMode: string;
  qaqcMode: string;
  invoiceMode: string;
  cost: number;
  kmlFilePath?: string;
  statusCode?: string;
  portafolio?: string;
  project?: string;
  activeCaptureDate?: string;
  pilotName?: string;
  assignedTo?: string;
  customerName?: string;
  location?: string;
  firstAction?: ActionOrdered;
  assigned?: string;
  statusId?: string;
  actions: ActionOrderConfiguredDto[];
  firstSecondaryAction: ActionOrderConfiguredDto;
  pilotSuccessName?: string;
  lastScopeOfWork?: string;
  pilotSuccessUserId?: string;
  missionOrderResume?: OrderResumeDto;
  captureId?: string | null;
  pilotId?: string | null;
  locationAddress?: string | null;
  locationCity?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  assignedToGuid?: string | null;
  missionCoordinatorName: string;
  missionCoordinatorUserId?: string;
  salesName: string;
  salesUserId?: string;
  qaqcName: string;
  qaqcRoleId: string;
  qaqcUserId: string;
  deliverablesNames?: string;
  reloadingNotes: boolean;
  activeCaptureTime?: string;
  fixedActiveCaptureDate?: string;
  fixedActiveCaptureTime?: string;
  preventQaQcComplete: boolean;
  jobId?: string;
  preventClientInvoiced: boolean;
  fixedCustomerRequestedCaptureDate?: string;
  fixedCustomerRequestedCaptureTime?: string;
  customerRequestedCaptureMustBe?: CaptureMustBeEnum;
  timeZone: string;
  airSpaceClasificationId: string;
  deliverableDueDate?: string;
  fixedDeliverableDueDate?: string;
  sLADays: number;
  slaDueDate: string;
  sLAStartDate: string;
  isAtRisk: boolean;
  cssClass: string;
  pilotRequested: number;
  pilotAccepted: number;
  pilotRejected: number;
  manualAirspaceWaiverRequired: boolean;
  notesCount: number;
  roleResponsible?: string;
  userResponsible?: string;
}

export interface PilotSourcingMissionsDto extends FullAuditedEntityDto<string> {
  missionId: string;
  customer: string;
  priority: string;
  location: string;
  captureDate: string;
  status: string;
  project: string;
  portfolio: string;
}

export interface PilotSourcingProjectDto extends FullAuditedEntityDto<string> {
  projectId: string;
  name: string;
  customer: string;
  location: string;
  recurence: string;
  percentagecomplete: string;
  priority: string;
  portfolio: string;
}

export interface PilotSourcingPorfolioDto extends FullAuditedEntityDto<string> {
  portfolioId: string;
  name: string;
  customer: string;
  percentagecomplete: string;
  priority: string;
  portfolio: string;
}

export interface MissionsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionsUpdateDto {
  name: string;
  summary: string;
  customerId: string;
  startData: string;
  finishData: string;
  industryId: string;
  projectId: string;
  state: enumState;
  locationId: string;
  additionalCharges?: string;
  additionalNotes?: string;
  orderId: string;
  thirdPartyId: string;
  priorityId: string;
  priorityDescription: string;
  missionStatus: number;
  pilotSourcingMode: string;
  qaqcMode: string;
  invoiceMode: string;
  cost: number;
}

export interface MissionLocationInformationDto {
  missionId: string;
  kmlFileId: string;
  kmlFilePath: string;
}

export interface NotesCreateDto {
  subject: string;
  body: string;
  categoryId: string;
  departamentId: string;
  state: enumState;
}

export interface NotesDto extends FullAuditedEntityDto<string> {
  subject: string;
  body: string;
  categoryId: string;
  categoryDescription: string;
  departamentId: string;
  departamentDescription: string;
  state: enumState;
  name: string;
  missionId: string;
  dateInLocalTime?: Date;
  dateFormat?: string;
  path?: string;
}

export interface NotesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface NotesUpdateDto {
  subject: string;
  body: string;
  categoryId: string;
  departamentId: string;
  state: enumState;
}

export interface OrderDetailsCreateDto {
  orderId: string;
  locationId: string;
  productId: string;
  qty: number;
  deliverableId: string;
  unitPrice: number;
  total: number;
  state: enumState;
}

export interface OrderDetailsDto extends FullAuditedEntityDto<string> {
  orderId: string;
  locationId: string;
  productId: string;
  productDescription: string;
  qty: number;
  deliverableId: string;
  unitPrice: number;
  total: number;
  state: enumState;
}

export interface OrderDetailsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface OrderDetailsUpdateDto {
  orderId: string;
  locationId: string;
  productId: string;
  qty: number;
  deliverableId: string;
  unitPrice: number;
  total: number;
  state: enumState;
}

export interface OrdersCreateDto {
  portfolioId: string;
  requestingCustomerId: string;
  billingCustomerId: string;
  endCustomerId: string;
  totalPrice: number;
  industryId: string;
  state: enumState;
}

export interface OrdersDto extends FullAuditedEntityDto<string> {
  portfolioId: string;
  requestingCustomerId: string;
  billingCustomerId: string;
  endCustomerId: string;
  totalPrice: number;
  industryId: string;
  industryDescription: string;
  state: enumState;
}

export interface OrdersExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface OrdersUpdateDto {
  portfolioId: string;
  requestingCustomerId: string;
  billingCustomerId: string;
  endCustomerId: string;
  totalPrice: number;
  industryId: string;
  state: enumState;
}

export interface PortafolioContactsCreateDto {
  portafolioId: string;
  contactId: string;
  state: enumState;
  projectManager: boolean;
}

export interface PortafolioContactsDto extends FullAuditedEntityDto<string> {
  portafolioId: string;
  contactId: string;
  state: enumState;
  projectManager: boolean;
}

export interface PortafolioContactsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PortafolioContactsUpdateDto {
  portafolioId: string;
  contactId: string;
  state: enumState;
  projectManager: boolean;
}

export interface PortafoliosCreateDto {
  name: string;
  customerId: string;
  customerName: string;
  subCustomerId: string;
  state: enumState;
}

export interface PortafoliosDto extends FullAuditedEntityDto<string> {
  name: string;
  customerId: string;
  billingCustomerId: string;
  subCustomerId: string;
  state: enumState;
  portfolioStatus: string;
  totalMissions: number;
}

export interface PortfolioStatusesDto {
  status: string;
  description: string;
  count: number;
}

export interface PortafoliosExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PortafoliosUpdateDto {
  name: string;
  customerId: string;
  billingCustomerId: string;
  subCustomerId: string;
  state: enumState;
}

export interface ProjectsCreateDto {
  name: string;
  portafolioId: string;
  recurence: recurrenceEnum;
  locationId: string;
  state: enumState;
}

export interface ProjectsDto extends FullAuditedEntityDto<string> {
  name: string;
  portafolioId: string;
  recurence: recurrenceEnum;
  totalMissions: number;
  state: enumState;
}

export interface ProjectsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ProjectsUpdateDto {
  name: string;
  portafolioId: string;
  recurence: recurrenceEnum;
  locationId: string;
  state: enumState;
}

export interface QuestionsCreateDto {
  description: string;
  questionTypeId: string;
  questionDataTypeId?: string;
  state: enumState;
}

export interface QuestionsDto extends FullAuditedEntityDto<string> {
  description: string;
  questionTypeId: string;
  questionTypeDescription: string;
  questionDataTypeId?: string;
  questionDataTypeDescription?: string;
  state: enumState;
}

export interface QuestionsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface QuestionsUpdateDto {
  description: string;
  questionTypeId: string;
  questionDataTypeId?: string;
  state: enumState;
}

export interface ResponsesCreateDto {
  description: string;
  questionId: string;
  responseTypeId: string;
  state: enumState;
}

export interface ResponsesDto extends FullAuditedEntityDto<string> {
  description: string;
  questionId: string;
  responseTypeId: string;
  responseTypeDescription: string;
  state: enumState;
}

export interface ResponsesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ResponsesUpdateDto {
  description: string;
  questionId: string;
  responseTypeId: string;
  state: enumState;
}

export interface ServicesCreateDto {
  name: string;
  description?: string;
  state: enumState;
  isProcessingService: boolean;
}

export interface ServicesDto extends FullAuditedEntityDto<string> {
  name: string;
  description?: string;
  state: enumState;
  isProcessingService?: boolean;
}

export interface ServicesExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface ServicesUpdateDto {
  name: string;
  description?: string;
  state: enumState;
  isProcessingService: boolean;
}

export interface TemplateCreateDto {
  name: string;
  structure: string;
  state?: number;
}

export interface TemplateDto extends FullAuditedEntityDto<string> {
  name: string;
  structure: string;
  state?: number;
  concurrencyStamp?: string;
}

export interface TemplateExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface TemplateUpdateDto {
  name: string;
  structure: string;
  state?: number;
  concurrencyStamp?: string;
}

export interface GetTemplatesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  structure?: string;
  state?: enumState;
  isPaginated?: boolean;
}

export interface PackageCreateDto {
  name: string;
  customerId: string;
  price: number;
  state?: enumState;
  code?: string;
}

export interface PackageDto extends FullAuditedEntityDto<string> {
  name: string;
  description: string;
  customerId: string;
  price: number;
  state?: enumState;
  industries?: string;
  code?: string;
}

export interface PackageExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface PackageUpdateDto {
  name: string;
  customerId: string;
  price: number;
  state?: enumState;
  code?: string;
}

export interface GetPackagesInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  name?: string;
  customerId?: string;
  priceMin?: number;
  priceMax?: number;
  state?: enumState;
  industryId?: string;
  code?: string;
}

export class Control<T> {
  value: T | undefined;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  options: { key: string; value: string }[];
  maxLength?: number;
  valueCheckBox: boolean;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
      type?: string;
      options?: { key: string; value: string }[];
      maxLength?: number;
      valueCheckBox?: boolean;
    } = {},
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.options = options.options || [];
    this.maxLength = options.maxLength === undefined ? 0 : options.maxLength;
    this.valueCheckBox = !!options.valueCheckBox;
  }
}

export class CheckBoxControl extends Control<string> {
  override controlType = 'CHECKBOX';
}

export class CalendarControl extends Control<string> {
  override controlType = 'CALENDAR';
}

export class DropDownListControl extends Control<string> {
  override controlType = 'DROPDOWNLIST';
}

export class InputControl extends Control<string> {
  override controlType = 'INPUT';
}

export class TextAreaControl extends Control<string> {
  override controlType = 'TEXTAREA';
}

export interface PilotPreferredLocationsDto {
  id?: string;
  center: string;
  radius: string;
  bounds: string;
  pilotId?: string;
}
export interface PilotPreferredLocationsCreateDto {
  center: string;
  radius: string;
  bounds: string;
  pilotId: string;
}

export interface TeamOrdersCreateDto {
  roleId: string;
  order: number;
  roleDescription?: string;
  state: enumState;
}

export interface TeamOrdersDto extends FullAuditedEntityDto<string> {
  roleId: string;
  order: number;
  roleDescription?: string;
  state: enumState;
}

export interface TeamOrdersExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  roleId?: string;
  order?: number;
  roleDescription?: string;
  state?: enumState;
}

export interface TeamOrdersUpdateDto {
  roleId: string;
  order: number;
  roleDescription?: string;
  state: enumState;
}

export interface CustomerLocationCreateDto {
  customerId?: string;
  locationId?: string;
}

export interface CustomerLocationDto extends FullAuditedEntityDto<string> {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface CustomerLocationUpdateDto {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface GetCustomerLocationsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  customerId?: string;
  locationId?: string;
}

export interface CustomerLocationCreateDto {
  customerId?: string;
  locationId?: string;
}

export interface CustomerLocationDto extends FullAuditedEntityDto<string> {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface CustomerLocationUpdateDto {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface GetCustomerLocationsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  customerId?: string;
  locationId?: string;
}

export interface StatusMissionOrderCreateDto {
  statusId: string;
  statusDescription?: string;
  statusEnum?: string;
  code?: string;
  order: number;
  state: enumState;
}

export interface StatusMissionOrderDto extends FullAuditedEntityDto<string> {
  statusId: string;
  statusDescription?: string;
  statusEnum?: string;
  code?: string;
  order: number;
  state: enumState;
}

export interface StatusMissionOrderExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  statusId?: string;
  statusDescription?: string;
  statusEnum?: string;
  code?: string;
  order?: number;
  state?: enumState;
}

export interface StatusMissionOrderUpdateDto {
  statusId: string;
  statusDescription?: string;
  statusEnum?: string;
  code?: string;
  order: number;
  state: enumState;
}
export interface CustomerLocationCreateDto {
  customerId?: string;
  locationId?: string;
}

export interface CustomerLocationDto extends FullAuditedEntityDto<string> {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface CustomerLocationUpdateDto {
  customerId?: string;
  locationId?: string;
  concurrencyStamp?: string;
}

export interface GetCustomerLocationsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  customerId?: string;
  locationId?: string;
}

export interface CaptureAttributesDto {
  attributeId: string;
  value: string;
  attributeName: string;
  attributeCodeType: string;
  fileName?: string;
  fileLink?: string;
}

export interface GetMissionLocationContactsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  missionId?: string;
  locationId?: string;
  contactId?: string;
}

export interface MissionLocationContactCreateDto {
  missionId?: string;
  locationId?: string;
  contactId?: string;
}

export interface MissionLocationContactDto extends FullAuditedEntityDto<string> {
  missionId?: string;
  locationId?: string;
  contactId?: string;
  concurrencyStamp?: string;
}

export interface MissionLocationContactExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  name?: string;
}

export interface MissionLocationContactUpdateDto {
  missionId?: string;
  locationId?: string;
  contactId?: string;
  concurrencyStamp?: string;
}

export interface UpdateCaptureDescriptionDto {
  captureId: string;
  description: string;
}

export interface BulkUploadDetailsCreateDto {
  bulkUploadId?: string;
  missionName?: string;
  missionSummary?: string;
  projectName?: string;
  recurrence?: string;
  recurenceId?: string;
  repeatsOn?: string;
  beginDate?: string;
  endDate?: string;
  repetitions?: string;
  time?: string;
  captureDateMust?: string;
  captureDateMustId?: string;
  deliverableDueDate?: string;
  deliverablesNote?: string;
  dataUploadingInstructions?: string;
  additionalNotes?: string;
  siteName?: string;
  locationStreet?: string;
  locationCountry?: string;
  countryId?: string;
  locationState?: string;
  stateId?: string;
  locationCity?: string;
  locationZipCode?: string;
  locationLatitude?: string;
  locationLongitude?: string;
  airspaceClassification?: string;
  airspaceClassificationId?: string;
  siteContactFrom?: string;
  contactTypeId?: string;
  siteContactEmail?: string;
  siteContactFirstName?: string;
  siteContactLastName?: string;
  siteContactPhone?: string;
  siteContactLevelofCoordination?: string;
  levelCommunicationId?: string;
  siteContactPreferredContact?: string;
  contactMethodId?: string;
  bulkUploadStatus?: enumBulkUpload;
  bulkUploadStatusDescription?: string;
  uploadFailDescription?: string;
  jobId?: number;
  missionId?: string;
  beginDateDT?: string;
  endDateDT?: string;
  deliverableDueDateDT?: string;
}

export interface BulkUploadDetailsExcelDto {
  MissionName?: string;
  MissionSummary?: string;
  ProjectName?: string;
  Recurrence?: string;
  RepeatsOn?: string;
  BeginDate?: string;
  EndDate?: string;
  Repetitions?: string;
  Time?: string;
  CaptureDateMust?: string;
  DeliverableDueDate?: string;
  DeliverablesNote?: string;
  DataUploadingInstructions?: string;
  AdditionalNotes?: string;
  SiteName?: string;
  LocationStreet?: string;
  LocationCountry?: string;
  LocationState?: string;
  StateId?: string;
  LocationCity?: string;
  LocationZipCode?: string;
  LocationLatitude?: string;
  LocationLongitude?: string;
  AirspaceClassification?: string;
  SiteContactFrom?: string;
  SiteContactEmail?: string;
  SiteContactFirstName?: string;
  SiteContactLastName?: string;
  SiteContactPhone?: string;
  SiteContactLevelofCoordination?: string;
  SiteContactPreferredContact?: string;
}

export interface BulkUploadDetailsDto extends FullAuditedEntityDto<string> {
  bulkUploadId?: string;
  missionName?: string;
  missionSummary?: string;
  projectName?: string;
  recurrence?: string;
  recurenceId?: string;
  repeatsOn?: string;
  beginDate?: string;
  endDate?: string;
  repetitions?: string;
  time?: string;
  captureDateMust?: string;
  captureDateMustId?: string;
  deliverableDueDate?: string;
  deliverablesNote?: string;
  dataUploadingInstructions?: string;
  additionalNotes?: string;
  siteName?: string;
  locationStreet?: string;
  locationCountry?: string;
  countryId?: string;
  locationState?: string;
  stateId?: string;
  locationCity?: string;
  locationZipCode?: string;
  locationLatitude?: string;
  locationLongitude?: string;
  airspaceClassification?: string;
  airspaceClassificationId?: string;
  siteContactFrom?: string;
  contactTypeId?: string;
  siteContactEmail?: string;
  siteContactFirstName?: string;
  siteContactLastName?: string;
  siteContactPhone?: string;
  siteContactLevelofCoordination?: string;
  levelCommunicationId?: string;
  siteContactPreferredContact?: string;
  contactMethodId?: string;
  bulkUploadStatus?: enumBulkUpload;
  bulkUploadStatusDescription?: string;
  uploadFailDescription?: string;
  jobId?: number;
  missionId?: string;
  beginDateDT?: string;
  endDateDT?: string;
  deliverableDueDateDT?: string;
}

export interface BulkUploadDetailsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  bulkUploadId?: string;
  bulkUploadStatus?: enumBulkUpload;
}

export interface BulkUploadDetailsUpdateDto {
  bulkUploadId?: string;
  missionName?: string;
  missionSummary?: string;
  projectName?: string;
  recurrence?: string;
  recurenceId?: string;
  repeatsOn?: string;
  beginDate?: string;
  endDate?: string;
  repetitions?: string;
  time?: string;
  captureDateMust?: string;
  captureDateMustId?: string;
  deliverableDueDate?: string;
  deliverablesNote?: string;
  dataUploadingInstructions?: string;
  additionalNotes?: string;
  siteName?: string;
  locationStreet?: string;
  locationCountry?: string;
  countryId?: string;
  locationState?: string;
  stateId?: string;
  locationCity?: string;
  locationZipCode?: string;
  locationLatitude?: string;
  locationLongitude?: string;
  airspaceClassification?: string;
  airspaceClassificationId?: string;
  siteContactFrom?: string;
  contactTypeId?: string;
  siteContactEmail?: string;
  siteContactFirstName?: string;
  siteContactLastName?: string;
  siteContactPhone?: string;
  siteContactLevelofCoordination?: string;
  levelCommunicationId?: string;
  siteContactPreferredContact?: string;
  contactMethodId?: string;
  bulkUploadStatus?: enumBulkUpload;
  bulkUploadStatusDescription?: string;
  uploadFailDescription?: string;
  jobId?: number;
  missionId?: string;
  beginDateDT?: string;
  endDateDT?: string;
  deliverableDueDateDT?: string;
}

export interface BulkUploadsBUDto extends FullAuditedEntityDto<string> {
  id: string;
  fileId: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName: string;
  customerId?: string;
  customerName?: string;
  priorityId?: string;
  priorityName?: string;
  industryId?: string;
  industryName?: string;
  jsonRequest?: string;
  bulkUploadDetails: BulkUploadDetailsDto[];
}

export interface BulkUploadsCreateBUDto {
  fileId: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName: string;
  customerId?: string;
  customerName?: string;
  priorityId?: string;
  priorityName?: string;
  industryId?: string;
  industryName?: string;
  jsonRequest?: string;
  bulkUploadDetails: BulkUploadDetailsDto[];
}

export interface BulkUploadsCreateDto {
  fileId: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName: string;
  customerId?: string;
  customerName?: string;
  priorityId?: string;
  priorityName?: string;
  industryId?: string;
  industryName?: string;
  jsonRequest?: string;
}

export interface BulkUploadsDto extends FullAuditedEntityDto<string> {
  id: string;
  fileId: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName: string;
  customerId?: string;
  customerName?: string;
  priorityId?: string;
  priorityName?: string;
  industryId?: string;
  industryName?: string;
  jsonRequest?: string;
}

export interface BulkUploadsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  fileId?: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName?: string;
}

export interface BulkUploadsUpdateDto {
  fileId: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName: string;
  customerId?: string;
  customerName?: string;
  priorityId?: string;
  priorityName?: string;
  industryId?: string;
  industryName?: string;
  jsonRequest?: string;
}

export interface GetBulkUploadsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  fileId?: string;
  bulkUploadStatus?: enumBulkUpload;
  fileName?: string;
  isPaginated?: boolean;
  id?: string;
  customerId?: string;
  priorityId?: string;
  industryId?: string;
}

export interface GetBulkUploadDetailsInput extends PagedAndSortedResultRequestDto {
  filterText?: string;
  bulkUploadId?: string;
  bulkUploadStatus?: enumBulkUpload;
  isPaginated?: boolean;
}

export interface UpdateDeliverableStatusDto {
  missionId: string;
  deliverableId: string;
  orderDetailId: string;
  captureId: string;
  status: number;
  creatorId: string;
}

export interface MissionPortfolioDto extends FullAuditedEntityDto<string> {
  missionName: string;
  missionSummary: string;
  missionStatus: number;
  missionStatusId?: string;
  projectId: string;
  projectName?: string;
  locationAddress?: string | null;
  locationCity?: string | null;
  activeCaptureDate?: string;
  activeCaptureTime?: string;
  fixedActiveCaptureDate?: string;
  fixedActiveCaptureTime?: string;
  pilotName?: string;
  assignedTo?: string;
  customerName?: string;
  location?: string;
  deliverablesNames?: string;
  captureId?: string | null;
  portfolioId: string;
  portafolio?: string;
  state: enumState;
  locationId: string;
}

export interface PortfolioDetailedDto {
  name: string;
  customerId: string;
  customerContactId?: string;
  billingCustomerId?: string;
  billingCustomerContactId?: string;
  subCustomerId?: string;
  state: enumState;
  customerName?: string;
  customerContactName?: string;
  portfolioRange?: string;
}

export interface NavigateToMissionDetailsDto {
  missionId: string;
  navigateToCapturePilotSourcingTab?: boolean;
  currentCaptureId?: string;
}

export interface UpdatePilotPayDto {
  captureId: string;
  expenseTypeId?: string;
  paymentDate?: string;
  pilotPay?: number;
  potentialPilotPay?: number;
  pilots?: string;
  action?: string;
  fixedPaymentDate?: string;
}

export interface MissionUpdateStorage {
  missionId: string;
  missionName: string;
  description: string;
  priorityId: string;
  orderPriority: string;
  industryId: string;
  orderIndustry: string;
  customerRequestedCaptureDate: string;
  customerRequestedCaptureTime: string;
  customerRequestedCaptureMustBe: CaptureMustBeEnum;
  missionAssetOrder?: string;
  fixedCustomerRequestedCaptureDate: string;
  fixedCustomerRequestedCaptureTime: string;
  customerId: string;
}

export interface OrderMissionEventsCreateDto {
  description?: string;
  code?: string;
  order: number;
  state: enumState;
}

export interface OrderMissionEventsDto extends FullAuditedEntityDto<string> {
  description?: string;
  code?: string;
  order: number;
  state: enumState;
}

export interface OrderMissionEventsExcelDownloadDto {
  downloadToken?: string;
  filterText?: string;
  description?: string;
  code?: string;
  order?: number;
  state?: enumState;
}

export interface OrderMissionEventsUpdateDto {
  description?: string;
  code?: string;
  order: number;
  state: enumState;
}

export interface MissionSLADto {
  missionId: string;
  slaId: string;
  slaMilestoneId: string;
  name: string;
  startDate: any;
  dueDate: any;
  statusId: string;
  isActive: boolean;
  days: number;
  isCustom: boolean;
  achievementDate: Date;
  order: number;
  isMatchWithDueDate: boolean;
  isMatchWithCaptureDate: boolean;
}

export interface MissionUpdateLocation {
  missionId: string;
  locationId?: string;
  siteName?: string;
  city?: string | null;
  stateId: string;
  state?: string | null;
  countryId: string;
  country?: string | null;
  address?: string | null;
  zipCode?: string | null;
  longitude?: string | null;
  latitude?: string | null;
  airspaceClassificationId: string;
  airspaceClassification?: string | null;
  kmlFilePath?: string | null;
  kmlFileId?: string | null;
  originAuthToken?: string | null;
}

export interface MissionMetrics {
  timeOnCurrentStatus: number;
  reflys: number;
  currentStatus?: string;
  daysSinceRequest: number;
}
